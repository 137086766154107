import { HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { IChangeSet } from '../changes-dialog/changes-dialog.component';
import { IDetailDisplayItem } from '../detail/detail-list/detail-list.component';
import { AppStateService } from '../services/app-state.service';
import { AuditService } from '../services/audit.service';
import { LabpartnerService } from '../services/labpartner.service';
import { AuditedChangeResponse, Detail, Experiment, ExperimentType } from '../services/labpartner.service.model';
import {
  EVENT_MULTISELECT_DETAILS,
  EVENT_SCAN_DEVICE_ID,
  EVENT_SCAN_SAMPLE_TUBE_ID,
} from '../services/logging-constants';
import { LoggingService } from '../services/logging.service';
import { UserAccountService } from '../services/user-account.service';
import { DialogService } from '../shared/dialog.services';
import { NotificationService } from '../shared/notification.service';
import { parseMatches } from '../shared/regex-helper';

export interface ISingleParsedValueEvent {
  detailId: number;
  previousParsedValue?: string;
  parsedValue?: string;
}

@Component({
  selector: 'app-scan-display',
  templateUrl: './scan-display.component.html',
  styleUrls: ['./scan-display.component.scss'],
})
export class ScanDisplayComponent implements OnChanges {
  @Input() detail!: Detail;
  @Input() detailId!: any;
  @Input() colValue!: any;
  @Input() colName!: string;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];
  @Input() disabled: boolean = false;
  @Input() selectedDetails: IDetailDisplayItem[] = [];

  @Output() onEnterEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() singleParsedValueEvent: EventEmitter<ISingleParsedValueEvent> = new EventEmitter<ISingleParsedValueEvent>();
  @Output() dialogsOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('scanInput') scanInput!: ElementRef;

  @HostListener('keydown.enter', ['$event.target'])
  onEnter(btn: any) {
    this.scanInput.nativeElement.blur();
    this.onEnterEvent.next(this.detailId);
  }

  currentValue!: string;
  isEdit: boolean = false;

  parsedValue?: string;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public accountService: UserAccountService,
    public appState: AppStateService,
    private dialogService: DialogService,
    private labPartnerService: LabpartnerService,
    private auditService: AuditService,
    private notificationService: NotificationService,
    private loggingService: LoggingService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedValue = parseMatches(this.colValue);
    this.currentValue = this.colValue;
    this.updateEditMode();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onFocus(evt: FocusEvent) {
    const target = evt.target as HTMLInputElement;
    target.setSelectionRange(0, target.value.length);
  }

  setEdit() {
    this.isEdit = true;
    setTimeout(() => {
      this.scanInput.nativeElement.focus();
    }, 0);
  }

  async saveValue() {
    if (this.currentValue == this.colValue) {
      this.currentValue = this.colValue;
      this.updateEditMode();
      return;
    }

    if (this.selectedDetails.length == 0) {
      this.saveSingleScan();
    }

    if (this.selectedDetails.length > 0) {
      this.saveMultipleScans();
    }
  }

  private getAuditIdentifier(sampleLabel: string, conditionLabel: string, replicateNo: number) {
    return `Detail ${sampleLabel} ${conditionLabel} ${replicateNo}`;
  }

  private async saveSingleScan() {
    const prevParsedValue = this.parsedValue;
    this.parsedValue = parseMatches(this.currentValue);

    this.emitNextParsedValue(this.detailId, prevParsedValue, this.parsedValue);

    if (!this.colValue && this.colValue != this.currentValue) {
      await this.saveAsync(this.currentValue);
    } else {
      const dialogRef = this.dialogService.openConfirmChangesDialog(
        [
          {
            identifier: this.getAuditIdentifier(
              this.detail.sampleLabel,
              this.detail.conditionLabel,
              this.detail.replicateNo
            ),
            field: this.colName,
            oldValue: this.colValue,
            newValue: this.currentValue,
          },
        ],
        this.appState.GetCurrentExperiment().type,
        {}
      );

      dialogRef.afterClosed().subscribe(async data => {
        if (
          data?.submitClicked &&
          (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          await this.saveAsync(this.currentValue, data.batchReason);
        } else {
          this.currentValue = this.colValue;
          this.parsedValue = parseMatches(this.colValue);
          this.emitNextParsedValue(this.detailId, prevParsedValue, this.parsedValue);
          this.updateEditMode();
        }
      });
    }
  }

  private async saveMultipleScans() {
    this.parsedValue = parseMatches(this.currentValue);

    const isSampleTubeScan = this.colName == 'Sample Tube ID';
    const isDeviceScan = this.colName == 'Device ID';

    const changes: IChangeSet[] = [];
    const targetDetails: IDetailDisplayItem[] = [];

    const pushTargetDetailsAndChanges = (detail: IDetailDisplayItem, oldValue: string) => {
      if (oldValue != this.currentValue) {
        targetDetails.push(detail);
        changes.push({
          identifier: this.getAuditIdentifier(detail.sampleLabel, detail.conditionLabel, detail.replicateNo),
          field: this.colName,
          oldValue: oldValue.trim(),
          newValue: this.currentValue,
        });
        this.emitNextParsedValue(detail.detailId, parseMatches(oldValue), this.parsedValue);
      }
    };

    if (isSampleTubeScan) {
      this.selectedDetails.forEach(d => pushTargetDetailsAndChanges(d, d.sampleTubeID));
    }

    if (isDeviceScan) {
      this.selectedDetails.forEach(d => pushTargetDetailsAndChanges(d, d.deviceID));
    }

    const allRowsEmptyToValue = changes.every(x => !x.oldValue && x.newValue);

    // Store off previous SampleTube / Device Ids by detailId
    const detailIdSampleTubeIdMap: { [detailId: number]: string } = {};
    const detailIdDeviceIdMap: { [detailId: number]: string } = {};
    targetDetails.forEach(d => {
      // One-off case for the current single-modified value
      if (d.detailId == this.detailId) {
        detailIdSampleTubeIdMap[d.detailId] = this.detail.sampleTubeID;
        detailIdDeviceIdMap[d.detailId] = this.detail.deviceID;
      } else {
        detailIdSampleTubeIdMap[d.detailId] = d.sampleTubeID;
        detailIdDeviceIdMap[d.detailId] = d.deviceID;
      }

      if (isSampleTubeScan) {
        d.sampleTubeID = this.currentValue;
      }

      if (isDeviceScan) {
        d.deviceID = this.currentValue;
      }
    });

    const cancelChanges = () => {
      this.dialogsOpen.emit(false);
      const parsedCurrentValue = parseMatches(this.colValue);

      targetDetails.forEach(d => {
        if (isSampleTubeScan) {
          this.colValue = this.currentValue = this.parsedValue = detailIdSampleTubeIdMap[d.detailId];
          d.sampleTubeID = detailIdSampleTubeIdMap[d.detailId];
          this.emitNextParsedValue(d.detailId, parsedCurrentValue, parseMatches(d.sampleTubeID));
        }

        if (isDeviceScan) {
          this.colValue = this.currentValue = this.parsedValue = detailIdDeviceIdMap[d.detailId];
          d.deviceID = detailIdDeviceIdMap[d.detailId];
          this.emitNextParsedValue(d.detailId, parsedCurrentValue, parseMatches(d.deviceID));
        }
      });

      this.updateEditMode();
    };

    const confirmResult = this.dialogService.openConfirmDialog(
      `This action will modify data for ${targetDetails.length} of ${this.selectedDetails.length} selected Details` +
      ', are you sure you want to continue?'
    );

    this.dialogsOpen.emit(true);

    confirmResult.afterClosed().subscribe(confirmed => {
      if (!confirmed) {
        cancelChanges();
        return;
      }

      if (allRowsEmptyToValue) {
        this.saveMultipleScanIds(
          isSampleTubeScan,
          isDeviceScan,
          detailIdSampleTubeIdMap,
          detailIdDeviceIdMap,
          this.currentValue,
          targetDetails,
          ''
        );
      } else {
        const dialogRef = this.dialogService.openConfirmChangesDialog(
          changes,
          this.appState.GetCurrentExperiment().type,
          {}
        );

        dialogRef.afterClosed().subscribe(async data => {
          if (
            data?.submitClicked &&
            (data?.reasonProvided ||
              this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
          ) {
            this.saveMultipleScanIds(
              isSampleTubeScan,
              isDeviceScan,
              detailIdSampleTubeIdMap,
              detailIdDeviceIdMap,
              this.currentValue,
              targetDetails,
              data.batchReason
            );
          } else {
            cancelChanges();
          }
        });
      }
    });
  }

  private emitNextParsedValue(detailId: number, prevParsedValue?: string, parsedValue?: string) {
    if (this.parsedValue != '000000') {
      this.singleParsedValueEvent.next({
        detailId: detailId,
        previousParsedValue: prevParsedValue,
        parsedValue: parsedValue,
      });
    }
  }

  private updateEditMode(): void {
    if (this.currentValue && this.currentValue.length > 0) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  }

  private async saveAsync(id: string, batchReason?: string) {
    if (this.colName == 'Sample Tube ID') {
      await this.onSaveSampleTubeIdAsync(this.currentValue, batchReason);
    } else if (this.colName == 'Device ID') {
      await this.onSaveDeviceIdAsync(this.currentValue, batchReason);
    }
  }

  private async onSaveSampleTubeIdAsync(sampleTubeId: string, batchReason?: string) {
    // Store off previous SampleTubeId
    const prevId = this.detail.sampleTubeID;

    try {
      // Assume success, this also preps us for change detection if an error occurs
      this.detail.sampleTubeID = sampleTubeId;
      await this.labPartnerService.setSampleTubeIDAsync(this.detailId, sampleTubeId, batchReason);
    } catch (err: any) {
      // Reset the value if it does not succeed
      this.detail.sampleTubeID = prevId;

      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    }

    // Regardless, let's attempt to get the latest and greatest info for the single row
    // For the most part, if this fails it is OK because we have updated the UI ourselves
    await this.refreshSingleDetailAsync(this.detailId, this.detail, true, false);
  }

  private saveMultipleScanIds(
    isSampleTubeScan: boolean,
    isDeviceScan: boolean,
    detailIdSampleTubeIdMap: { [detailId: number]: string },
    detailIdDeviceIdMap: { [detailId: number]: string },
    newScannedId: string,
    targetDetails: IDetailDisplayItem[],
    batchReason?: string
  ) {
    if (!this.currentExperiment?.experimentId) {
      return;
    }

    const finalizeCallback = () => {
      // Regardless, let's attempt to get the latest and greatest info for the single row
      // For the most part, if this fails it is OK because we have updated the UI ourselves
      this.refreshMultipleDetails(targetDetails, isSampleTubeScan, isDeviceScan);
    };

    const showErrorNotification = (err: any) => {
      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    };

    const successCallback = (res: HttpResponse<Object>, telemetryEvent: string, itemsSelected: number) => {
      if (res.ok) {
        const telemetryProps: { [key: string]: number | string } = {
          ExperimentId: this.currentExperiment!.experimentId,
          DeviceType: this.appState.GetCurrentExperiment().deviceType,
          ExperimentOwner: this.currentExperiment!.createdBy ?? 0,
        };

        if (targetDetails.length > 1) {
          telemetryProps.MultiselectAction = telemetryEvent;
          telemetryProps.ItemsSelected = itemsSelected;
          this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, telemetryProps);
        }
      }
    };

    if (isSampleTubeScan) {
      this.labPartnerService
        .setMultipleSampleTubeIds(
          this.currentExperiment?.experimentId,
          targetDetails.map(d => d.detailId),
          newScannedId,
          batchReason ?? ''
        )
        .pipe(finalize(async () => await finalizeCallback()))
        .subscribe(
          res => successCallback(res, EVENT_SCAN_SAMPLE_TUBE_ID, targetDetails.length),
          (err: any) => {
            targetDetails.forEach(d => (d.sampleTubeID = detailIdSampleTubeIdMap[d.detailId]));
            showErrorNotification(err);
          }
        );
    }

    if (isDeviceScan) {
      this.labPartnerService
        .setMultipleDeviceIds(
          this.currentExperiment?.experimentId,
          targetDetails.map(d => d.detailId),
          newScannedId,
          batchReason ?? ''
        )
        .pipe(finalize(async () => await finalizeCallback()))
        .subscribe(
          res => successCallback(res, EVENT_SCAN_DEVICE_ID, targetDetails.length),
          (err: any) => {
            targetDetails.forEach(d => (d.deviceID = detailIdDeviceIdMap[d.detailId]));
            showErrorNotification(err);
          }
        );
    }
  }

  private async onSaveDeviceIdAsync(deviceId: string, batchReason?: string) {
    // Store off previous DeviceId
    const prevId = this.detail.deviceID;
    try {
      // Assume success, this also preps us for change detection if an error occurs
      this.detail.deviceID = deviceId;
      await this.labPartnerService.setDeviceIDAsync(this.detailId, deviceId, batchReason);
    } catch (err: any) {
      // Reset the value if it does not succeed
      this.detail.deviceID = prevId;

      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    }

    // Regardless, let's attempt to get the latest and greatest info for the single row
    // For the most part, if this fails it is OK because we have updated the UI ourselves
    await this.refreshSingleDetailAsync(this.detailId, this.detail, false, true);
  }

  private async refreshSingleDetailAsync(
    detailId: number,
    prevDetail: Detail,
    isSampleTubeId: boolean = false,
    isDeviceId: boolean = false
  ) {
    await this.auditService.getAuditCounts(this.detail.experimentId).toPromise();
    const refreshedDetail = await this.labPartnerService.getDetailById(detailId).toPromise();

    if (!refreshedDetail) { return; }

    prevDetail.sampleLabel = refreshedDetail.sampleLabel;
    prevDetail.sampleName = refreshedDetail.sampleName;
    prevDetail.conditionLabel = refreshedDetail.conditionLabel;
    prevDetail.replicateNo = refreshedDetail.replicateNo;
    prevDetail.readableBarcode = refreshedDetail.readableBarcode;
    prevDetail.barcodeID = refreshedDetail.barcodeID;

    if (isSampleTubeId) {
      prevDetail.sampleTubeID = refreshedDetail.sampleTubeID;
    }

    if (isDeviceId) {
      prevDetail.deviceID = refreshedDetail.deviceID;
    }

    prevDetail.unevaluable = refreshedDetail.unevaluable;
    prevDetail.sampleUnevaluable = refreshedDetail.sampleUnevaluable;
    prevDetail.conditionUnevaluable = refreshedDetail.conditionUnevaluable;
  }

  private refreshMultipleDetails(
    details: IDetailDisplayItem[],
    isSampleTubeId: boolean = false,
    isDeviceId: boolean = false
  ) {
    const detailMap: { [detailId: number]: IDetailDisplayItem } = {};

    this.auditService
      .getAuditCounts(this.detail.experimentId)
      .pipe(
        mergeMap(_ => {
          const detailIds: number[] = [];
          details.forEach(d => {
            detailIds.push(d.detailId);
            detailMap[d.detailId] = d;
          });

          return this.labPartnerService.getDetailsByIds(detailIds);
        })
      )
      .subscribe(refreshedDetails => {
        for (const refreshedDetail of refreshedDetails) {
          detailMap[refreshedDetail.detailId].sampleLabel = refreshedDetail.sampleLabel;
          detailMap[refreshedDetail.detailId].sampleName = refreshedDetail.sampleName;
          detailMap[refreshedDetail.detailId].conditionLabel = refreshedDetail.conditionLabel;
          detailMap[refreshedDetail.detailId].replicateNo = refreshedDetail.replicateNo;
          detailMap[refreshedDetail.detailId].readableBarcode = refreshedDetail.readableBarcode;
          detailMap[refreshedDetail.detailId].barcodeID = refreshedDetail.barcodeID;

          if (isSampleTubeId) {
            detailMap[refreshedDetail.detailId].sampleTubeID = refreshedDetail.sampleTubeID;
          }

          if (isDeviceId) {
            detailMap[refreshedDetail.detailId].deviceID = refreshedDetail.deviceID;
          }

          detailMap[refreshedDetail.detailId].unevaluable = refreshedDetail.unevaluable;
          detailMap[refreshedDetail.detailId].sampleUnevaluable = refreshedDetail.sampleUnevaluable;
          detailMap[refreshedDetail.detailId].conditionUnevaluable = refreshedDetail.conditionUnevaluable;
        }
      });
  }
}
