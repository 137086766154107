<div class="app-xls-import-div">
  <app-xls-import
    matTooltip="Import samples from excel spread sheet"
    (jsonChanged)="changedJSON($event)"
    (fileChanged)="onXlsImportFileChanged($event)"
    [disabled]="!(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete') || childIsInEditMode"
    [isCombinedSampleConditionImport]="true"
    *ngIf="currentExperiment | statusIsNot : ['Complete']">
  </app-xls-import>
</div>
<mat-tab-group
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="selectedTabChanged($event)"
  [disablePagination]="true">
  <mat-tab label="Samples" [disabled]="childIsInEditMode">
    <app-sample-list
      #sampleList
      [experimentId]="experimentId"
      [currentExperiment]="currentExperiment"
      [currentRoles]="currentRoles"
      [samplesNotesCountMap]="(notesService.experimentNotesCountMap | async)?.Samples"
      [samplesAuditCountMap]="samplesAuditCountMap"
      [conditionsListCmp]="conditionList"
      (isEditing)="childIsInEditMode = $event"></app-sample-list>
  </mat-tab>
  <mat-tab label="Conditions" [disabled]="childIsInEditMode">
    <app-condition-list
      #conditionList
      [experimentId]="experimentId"
      [currentExperiment]="currentExperiment"
      [currentRoles]="currentRoles"
      [conditionsNotesCountMap]="(notesService.experimentNotesCountMap | async)?.Condition"
      [conditionsAuditCountMap]="conditionsAuditCountMap"
      [samplesListCmp]="sampleList"
      (isEditing)="childIsInEditMode = $event"></app-condition-list>
  </mat-tab>
</mat-tab-group>
