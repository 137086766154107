import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration, FeatureFlags } from './configuration.service.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable(
  //   {
  //   providedIn: 'root'  // not using automatic injection into root module since we are creating it manually in main.ts bootstrapping
  // }
)
export class ConfigurationService {

  private initialized: boolean = false;
  private configuration: Configuration | undefined;
  private _featureFlags: BehaviorSubject<FeatureFlags> = new BehaviorSubject<FeatureFlags>({} as FeatureFlags);
  public featureFlags$: Observable<FeatureFlags> = this._featureFlags.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  public async initializeConfiguration(): Promise<void> {
    if (this.initialized) {
      console.debug('ConfigurationService already initialized. Skipping another fetch');
      return;
    }

    const configData = await this.httpClient.get<Configuration>('assets/configuration.json').toPromise();

    if (!configData) { return; }

    this.configuration = {
      apiServiceEndpoint: configData.apiServiceEndpoint,
      apiScope: configData.apiScope,
      appInsightsKey: configData.appInsightsKey,
      msalConfig: {
        clientId: configData.msalConfig.clientId,
        authority: configData.msalConfig.authority,
        redirectUri: configData.msalConfig.redirectUri
      },
      noAccessContactMessage: configData.noAccessContactMessage,
      instructionVideoUrl: configData.instructionVideoUrl,
      environmentName: configData.environmentName,
      featureFlags: configData.featureFlags
    };

    if (configData.featureFlags) {
      this._featureFlags.next(configData.featureFlags);
    }

    console.log("configuration initialized");

    this.initialized = true;

  }

  public getConfig(): Configuration {
    if (!this.initialized) {
      throw new Error('ConfigurationService must be initialized first.');
    }

    return this.configuration!;
  }

  public getFeatureFlags(): FeatureFlags | undefined {
    if (!this.initialized || !this.configuration) {
      throw new Error('ConfigurationService must be initialized first.');
    }

    return this.configuration.featureFlags ? this.configuration.featureFlags : {} as FeatureFlags;
  }
}
