import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  CompleteStatus,
  Condition,
  DetailsGeneratedStatus,
  Experiment,
  ExperimentType,
  FileToUpload,
  ImportData,
  ReadyToTestStatus,
  Sample,
  StudyType,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { LoggingService } from 'src/app/services/logging.service';
import {
  EVENT_APPEND_CONDITIONS,
  EVENT_APPEND_SAMPLES,
  EVENT_IMPORT_CONDITIONS,
  EVENT_IMPORT_SAMPLES,
  EVENT_UPLOAD_FILE_IMPORT,
  TAB_PAGE_NAMES,
} from 'src/app/services/logging-constants';
import { AppStateService } from 'src/app/services/app-state.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { xlsImportData } from '../xls-import/xls-import.component';
import { IChangeSet, IReplicatePair } from '../changes-dialog/changes-dialog.component';
import {
  AuditCountMap,
  AuditObjectIdCountMap,
  AuditObjectName,
  IAuditChangeReasons,
} from '../services/audit.service.models';
import { ISampleDisplayItem, SampleListComponent } from '../samples/sample-list/sample-list.component';
import { IConditionDisplayItem, ConditionListComponent } from '../conditions/condition-list/condition-list.component';
import { NotesService } from '../services/notes-service.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
})
export class InputsComponent extends BaseComponent implements OnDestroy, OnChanges {
  @Input() experimentId: number = 0;
  @Input() inputSelected: boolean = false;
  @Input() auditCountMap?: AuditCountMap;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ViewChild(SampleListComponent) samplesListCmp?: SampleListComponent;
  @ViewChild(ConditionListComponent) conditionsListCmp?: ConditionListComponent;

  samplesAuditCountMap?: AuditObjectIdCountMap;
  conditionsAuditCountMap?: AuditObjectIdCountMap;

  childIsInEditMode: boolean = false;

  selectedIndex: number = 0;
  hasVisited: boolean = false;

  importData!: ImportData;
  selectedImportFile?: File;

  CompleteStatus = CompleteStatus;
  StudyType = StudyType;

  private MAX_RECORDS_PER_IMPORT = this.labPartnerService.MAX_RECORDS_PER_IMPORT;

  private MAX_REPLICATES_PER_IMPORT = this.labPartnerService.MAX_REPLICATES_PER_IMPORT;

  constructor(
    private apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    public notesService: NotesService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private loggingService: LoggingService,
    private labPartnerService: LabpartnerService
  ) {
    super();
  }

  // This is a tweak to fix tab highlighting on the sub tabs (Sample/Condition)
  // That would break due to being loaded behind-the-scenes if the route auto-set to Details
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputSelected?.currentValue) {
      if (!this.hasVisited) {
        this.selectedIndex = 1;
        setTimeout(() => (this.selectedIndex = 0));
        this.hasVisited = true;
      }
    }

    if (!changes.auditCountMap || !changes.auditCountMap.currentValue) {
      return;
    }

    if (changes.auditCountMap.currentValue?.Samples != changes.auditCountMap.previousValue?.Samples) {
      this.samplesAuditCountMap = this.auditCountMap?.Samples ?? [];
    }

    if (changes.auditCountMap.currentValue?.Conditions != changes.auditCountMap.previousValue?.Conditions) {
      this.conditionsAuditCountMap = this.auditCountMap?.Conditions ?? [];
    }
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  async changedJSON(importData: { importedSamplesData: xlsImportData; importedConditionsData: xlsImportData }) {
    if (
      ((this.samplesListCmp?.listData?.data?.length ?? 0) > 0 ||
        (this.conditionsListCmp?.listData?.data?.length ?? 0) > 0) &&
      this.appState.GetCurrentExperiment().status < ReadyToTestStatus
    ) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to import this data?' +
        ' Previous data will be deleted including all the generated details.'
      );

      dialog.afterClosed().subscribe(async res => {
        if (res) {
          await this.performImportAsync(importData);
          this.reloadExperimentAfterImportSamplesAndConditions();
        }
      });
    } else if (this.appState.GetCurrentExperiment().status < DetailsGeneratedStatus) {
      // in this case, we won't have any details yet, just import over the top. no prompt
      await this.performImportAsync(importData);
    } else if (this.appState.GetCurrentExperiment().status >= ReadyToTestStatus) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to append this data to the current experiment?' +
        ' Detail records will be automatically generated for these new samples and conditions.'
      );

      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.performAppend(importData);
          this.reloadExperimentAfterImportSamplesAndConditions();
        }
      });
    }
  }

  onXlsImportFileChanged(evt: File): void {
    this.selectedImportFile = evt;
  }

  selectedTabChanged(event: MatTabChangeEvent) {
    if (event.index < 0 || event.index > 1) {
      console.error('unexpected tab index');
      return;
    }

    // Samples was 0 before and stays 0 now. Conditions was 1 before and stays 1 now.
    const pageName = TAB_PAGE_NAMES[event.index];
    this.loggingService.logPageView(pageName);
  }

  private async performImportAsync(importData: {
    importedSamplesData: xlsImportData;
    importedConditionsData: xlsImportData;
  }) {
    if (!this.selectedImportFile) {
      return;
    }

    if (importData.importedSamplesData.data.length > this.MAX_RECORDS_PER_IMPORT) {
      this.notificationService.error('Maximum Sample records per import is ' + this.MAX_RECORDS_PER_IMPORT);
      return;
    }

    if (importData.importedConditionsData.data.length > this.MAX_RECORDS_PER_IMPORT) {
      this.notificationService.error('Maximum Condition records per import is ' + this.MAX_RECORDS_PER_IMPORT);
      return;
    }

    if (this.currentExperiment?.replicates && this.currentExperiment.replicates > this.MAX_REPLICATES_PER_IMPORT) {
      this.notificationService.error('Maximum replicates number is ' + this.MAX_REPLICATES_PER_IMPORT);
      return;
    }

    let samples: Sample[] = this.getSamples(
      importData.importedSamplesData,
      this.samplesListCmp?.listData?.data.length ?? 0
    );
    let samplesImportData: ImportData | undefined =
      samples.length > 0 ? this.createImportData(importData.importedSamplesData) : undefined;

    let conditions: Condition[] = this.getConditions(
      importData.importedConditionsData,
      this.conditionsListCmp?.listData.data.length ?? 0
    );
    let conditionsImportData: ImportData | undefined =
      conditions.length > 0 ? this.createImportData(importData.importedConditionsData) : undefined;

    try {
      let importFileData: FileToUpload = {
        uploadId: 0,
        fileName: this.selectedImportFile.name,
        fileSize: this.selectedImportFile.size,
        fileType: this.selectedImportFile.type,
        lastModifiedTime: this.selectedImportFile.lastModified,
        lastModifiedDate: (this.selectedImportFile as any).lastModifiedDate,
        fileAsBase64: '',
        experimentId: this.experimentId,
        dateCreated: new Date(),
        createdBy: this.apiService.getLoggedInUser(),
      };

      let reader = new FileReader();

      var selectedImportFile = this.selectedImportFile;
      const fileReadResult = await new Promise<string | ArrayBuffer>(resolve => {
        reader.onload = ev => {
          resolve(ev?.target?.result ?? '');
        };
        reader.readAsDataURL(selectedImportFile);
      });

      const readerResult = fileReadResult?.toString() ?? '';
      if (!!!readerResult.trim()) {
        this.notificationService.error('Empty file or error reading file.');
        return;
      }
      importFileData.fileAsBase64 = readerResult;

      await this.apiService
        .createMultipleSamplesAndConditions(
          this.experimentId,
          importFileData,
          samples,
          conditions,
          samplesImportData,
          conditionsImportData
        )
        .toPromise();

      const props: { [key: string]: number | string } = {
        ExperimentId: this.experimentId,
        DeviceType: this.appState.GetCurrentExperiment().deviceType,
        ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
        NumberImported: 0,
      };

      if (samples.length > 0) {
        props.NumberImported = samples.length;
        this.loggingService.logEvent(EVENT_IMPORT_SAMPLES, props);
      }
      if (conditions.length > 0) {
        props.NumberImported = conditions.length;
        this.loggingService.logEvent(EVENT_IMPORT_CONDITIONS, props);
      }

      delete props['NumberImported'];
      this.loggingService.logEvent(EVENT_UPLOAD_FILE_IMPORT, props);

      this.samplesListCmp?.ngOnInit();
      this.conditionsListCmp?.ngOnInit();

      this.notificationService.success('Import complete');

      this.appState.SetExperimentDataChanged(this.experimentId);
    } catch (err: any) {
      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    }
  }

  private performAppend(importData: {
    importedSamplesData: xlsImportData;
    importedConditionsData: xlsImportData;
  }): void {
    const samplesListData = this.samplesListCmp?.listData;
    const conditionsListData = this.conditionsListCmp?.listData;

    let samplesHeader = importData.importedSamplesData.header;
    const preExistingSamples = samplesListData && samplesListData?.data.length > 0;

    let previousSamplesHeader: string[] = [];
    if (preExistingSamples) {
      previousSamplesHeader = [...this.parseHeaderArray(samplesListData!.data)];
    }

    let conditionsHeader = importData.importedConditionsData.header;
    const preExistingConditions = conditionsListData && conditionsListData?.data.length > 0;

    let previousConditionsHeader: string[] = [];
    if (preExistingConditions) {
      previousConditionsHeader = [...this.parseHeaderArray(conditionsListData!.data)];
    }

    let samplesToImport: boolean = false;
    let samplesHeaderMatch: boolean = !preExistingSamples;
    if (!!importData.importedSamplesData.header && importData.importedSamplesData.data.length > 0) {
      samplesToImport = true;
      if (preExistingSamples) {
        samplesHeaderMatch = this.arraysAreEqual(samplesHeader, previousSamplesHeader);
      }
    }

    let conditionsToImport: boolean = false;
    let conditionsHeaderMatch: boolean = !preExistingConditions;
    if (!!importData.importedConditionsData.header && importData.importedConditionsData.data.length > 0) {
      conditionsToImport = true;
      if (preExistingConditions) {
        conditionsHeaderMatch = this.arraysAreEqual(conditionsHeader, previousConditionsHeader);
      }
    }

    if (!samplesToImport && !conditionsToImport) {
      this.notificationService.warn('No Samples or Conditions found in workbook to import or append.');
    } else if (!samplesHeaderMatch && samplesToImport) {
      this.notificationService.warn(
        'The number of columns in the uploaded file for Samples does not match the number of columns in the existing data.' +
        ' Nothing has been added.'
      );
    } else if (!conditionsHeaderMatch && conditionsToImport) {
      this.notificationService.warn(
        'The number of columns in the uploaded file for Conditions does not match the number of columns in the existing data.' +
        ' Nothing has been added.'
      );
    } else {
      let samples: Sample[] = [];
      if (samplesToImport) {
        samples = this.getSamples(importData.importedSamplesData, samplesListData?.data.length ?? 0, true);
      }

      let conditions: Condition[] = [];
      if (conditionsToImport) {
        conditions = this.getConditions(importData.importedConditionsData, conditionsListData?.data.length ?? 0, true);
      }

      let changeSets: IChangeSet[] = [];

      if (samples.length > 0) {
        changeSets.push({
          identifier: AuditObjectName.Samples,
          field: '-',
          oldValue: '-',
          newValue: `+ ${samples.length} new samples`,
        });
      }

      if (conditions.length > 0) {
        changeSets.push({
          identifier: AuditObjectName.Conditions,
          field: '-',
          oldValue: '-',
          newValue: `+ ${conditions.length} new conditions`,
        });
      }

      const replicatePairs: IReplicatePair[] = [];

      // Net new combinations
      for (let sample of samples) {
        for (let condition of conditions) {
          replicatePairs.push({
            sampleId: sample.sampleId,
            sampleLabel: sample.label,
            conditionId: condition.conditionId,
            conditionLabel: condition.label,
            numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
          });
        }
      }

      // New combinations with new Samples as base
      if (this.conditionsListCmp?.listData.data) {
        for (let sample of samples) {
          for (let condition of this.conditionsListCmp?.listData.data) {
            replicatePairs.push({
              sampleId: sample.sampleId,
              sampleLabel: sample.label,
              conditionId: condition.conditionId,
              conditionLabel: condition.label,
              numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
            });
          }
        }
      }

      // New combinations with new Conditions as base
      if (this.samplesListCmp?.listData.data) {
        for (let condition of conditions) {
          for (let sample of this.samplesListCmp?.listData.data) {
            replicatePairs.push({
              sampleId: sample.sampleId,
              sampleLabel: sample.label,
              conditionId: condition.conditionId,
              conditionLabel: condition.label,
              numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
            });
          }
        }
      }

      let totalSamples = this.samplesListCmp ? this.samplesListCmp.listData.data.length + samples.length : samples.length;
      let totalConditions = this.conditionsListCmp ? this.conditionsListCmp.listData.data.length + conditions.length : conditions.length;

      if (totalSamples > this.MAX_RECORDS_PER_IMPORT) {
        this.notificationService.error('Maximum Sample records is ' + this.MAX_RECORDS_PER_IMPORT);
        return;
      }

      if (totalConditions > this.MAX_RECORDS_PER_IMPORT) {
        this.notificationService.error('Maximum Condition records is ' + this.MAX_RECORDS_PER_IMPORT);
        return;
      }

      if (changeSets.length == 0) {
        return;
      }

      const dialogRef = this.dialogService.openConfirmChangesDialog(
        changeSets,
        this.appState.GetCurrentExperiment().type,
        {
          multiReason: true,
          isAppend: true,
          defaultReplicates: this.appState.GetCurrentExperiment().replicates,
          replicateData: replicatePairs,
        }
      );

      dialogRef.afterClosed().subscribe(async data => {
        if (
          data?.submitClicked &&
          (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          try {
            if (this.samplesListCmp) {
              (this.samplesListCmp.listData as any) = undefined;
            }

            if (this.conditionsListCmp) {
              (this.conditionsListCmp.listData as any) = undefined;
            }

            let fieldReasons: { [key: string]: string } = {};

            for (let i = 0; i < changeSets.length; i++) {
              fieldReasons[changeSets[i].identifier] = data.fieldReasons[i];
            }

            const auditChangeReasons: IAuditChangeReasons = {
              batchReason: data.batchReason ?? '',
              fieldReasons: fieldReasons,
            };

            let samplesImportData: ImportData | undefined = samplesToImport
              ? this.createImportData(importData.importedSamplesData)
              : undefined;

            let conditionsImportData: ImportData | undefined = conditionsToImport
              ? this.createImportData(importData.importedConditionsData)
              : undefined;

            await this.apiService
              .appendMultipleSamplesAndConditions(
                this.experimentId,
                samples,
                conditions,
                replicatePairs.filter(rp => !rp.ignore && (rp.numberOfReplicates ?? 0) > 0),
                samplesImportData,
                conditionsImportData,
                auditChangeReasons
              )
              .toPromise();

            this.samplesListCmp?.ngOnInit();
            this.conditionsListCmp?.ngOnInit();
            this.appState.SetExperimentDataChanged(this.experimentId);

            this.notificationService.success('Import complete');

            const props: { [key: string]: number | string } = {
              ExperimentId: this.experimentId,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
              NumberImported: 0,
            };

            if (samples.length > 0) {
              props.NumberImported = samples.length;
              this.loggingService.logEvent(EVENT_APPEND_SAMPLES, props);
            }
            if (conditions.length > 0) {
              props.NumberImported = conditions.length;
              this.loggingService.logEvent(EVENT_APPEND_CONDITIONS, props);
            }
          } catch (err: any) {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        }
      });
    }
  }

  private parseHeaderArray(items: ISampleDisplayItem[] | IConditionDisplayItem[]) {
    let maxPropIndex = 0;
    let maxPropCount = 0;
    for (let i = 0; i < items.length; i++) {
      const curPropCount = Object.keys(items[i].data).length;
      if (maxPropCount < curPropCount) {
        maxPropCount = curPropCount;
        maxPropIndex = i;
      }
    }
    return Object.keys(items[maxPropIndex].data).map(x => x);
  }

  private createImportData(xlsImportData: xlsImportData): ImportData {
    return {
      importDataId: 0,
      experimentId: this.experimentId,
      type: 1,
      importSource: xlsImportData.file,
      importHeader: xlsImportData.header.length > 1 ? xlsImportData.header.slice(1).join('') : xlsImportData.header.join(''),
      dateCreated: new Date(),
      createdBy: this.apiService.getLoggedInUser(),
    };
  }

  private arraysAreEqual(newHeader: string[], previousHeader: string[]): boolean {
    return (
      Array.isArray(newHeader) &&
      Array.isArray(previousHeader) &&
      newHeader.length === previousHeader.length &&
      newHeader.every((val, index) => val === previousHeader[index])
    );
  }

  private getSamples(importData: xlsImportData, currentListDataLength: number, isAppend: boolean = false): Sample[] {
    if (importData.header.length == 0 && importData.data.length == 0) {
      return [];
    }
    let firstPropertyName: string = importData.header[0];
    let count = isAppend ? currentListDataLength : 0;

    let samples: Sample[] = [];
    for (let elem of importData.data) {
      count++;

      let formatedLabel = count.toString().padStart(2, '0');

      let sample: Sample = {
        sampleId: 0,
        experimentId: this.experimentId,
        label: 'S' + formatedLabel,
        name: elem[firstPropertyName].toString(),
        importData: JSON.stringify(elem),
        unevaluable: false,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        createdBy: this.apiService.getLoggedInUser(),
      };

      samples.push(sample);
    }
    return samples;
  }

  private getConditions(
    importData: xlsImportData,
    currentListDataLength: number,
    isAppend: boolean = false
  ): Condition[] {
    if (importData.header.length == 0 && importData.data.length == 0) {
      return [];
    }
    let firstPropertyName: string = importData.header[0];
    let count = isAppend ? currentListDataLength : 0;

    let conditions: Condition[] = [];
    for (let elem of importData.data) {
      count++;
      let formatedLabel = count.toString().padStart(2, '0');

      let condition: Condition = {
        conditionId: 0,
        experimentId: this.experimentId,
        label: 'C' + formatedLabel,
        name: elem[firstPropertyName].toString(),
        importData: JSON.stringify(elem),
        unevaluable: false,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        createdBy: this.apiService.getLoggedInUser(),
      };
      conditions.push(condition);
    }
    return conditions;
  }

  reloadExperimentAfterImportSamplesAndConditions() {
    return this.apiService.getExperiment(this.experimentId)
      .subscribe({
        next: (experiment: Experiment) => {
          this.appState.SetCurrentExperiment(experiment);
        },
      });
  }
}
