<div class="search-div">
  <button mat-raised-button (click)="onCreate()" disabled><mat-icon>add</mat-icon>Create</button>
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <button mat-raised-button aria-label="Cancel" (click)="endEditMode()" *ngIf="isEditMode && !postEditLoading">
    <mat-icon>cancel</mat-icon>Cancel
  </button>
  <button
    id="inline-save-btn"
    mat-raised-button
    [attr.aria-label]="isEditMode ? 'Save' : 'Edit Data'"
    (click)="isEditMode ? onConditionsEditCommit() : onConditionsEdit()"
    [disabled]="!(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsReadyToTest')"
    *ngIf="(currentExperiment | statusIs : ['ReadyToTest']) && listData">
    <ng-container *ngIf="!isEditMode"><mat-icon>edit</mat-icon>Edit Data</ng-container>
    <ng-container *ngIf="isEditMode"><mat-icon>save</mat-icon>Save</ng-container>
  </button>
  <span class="notification-msg-box" *ngIf="isEditMode && !postEditLoading">
    ** Currently in Edit mode, don't forget to Save! **
  </span>

  <div class="progress-bar-container" *ngIf="apiService.isLoading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<ng-template
  #tmplSnackbar
  let-curVal="curVal"
  let-newVal="newVal"
  let-colName="colName"
  let-rowIdx="rowIndex"
  let-colIdx="colIndex">
  <div *ngIf="focusRowIdx == rowIdx && focusColIdx == colIdx" class="edit-panel">
    <div class="value-title">Currently Editing</div>
    <div class="column-name">{{ colName }}</div>
    <div class="value-title" *ngIf="curVal">Current</div>
    <div class="panel-current-value" [ngClass]="{ modified: curVal != newVal }" *ngIf="curVal">{{ curVal }}</div>
    <div class="value-title">New</div>
    <div class="panel-new-value" [ngClass]="{ modified: curVal != newVal }">{{ newVal }}</div>
  </div>
</ng-template>

<ng-template
  #tmplSnackbarHeader
  let-curVal="curVal"
  let-newVal="newVal"
  let-colName="colName"
  let-rowIdx="rowIndex"
  let-colIdx="colIndex">
  <div *ngIf="focusRowIdx == -1 && focusColIdx == colIdx" class="edit-panel">
    <div class="value-title">Currently Editing</div>
    <div class="column-name">{{ colName }}</div>
    <div class="value-title" *ngIf="curVal">Current</div>
    <div class="panel-current-value" [ngClass]="{ modified: curVal != newVal }" *ngIf="curVal">{{ curVal }}</div>
    <div class="value-title">New</div>
    <div class="panel-new-value" [ngClass]="{ modified: curVal != newVal }">{{ newVal }}</div>
  </div>
</ng-template>

<div class="mat-elevation-z8">
  <mat-table [dataSource]="listData" matSort>
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Label</mat-header-cell>
      <mat-cell *matCellDef="let element; let rowIndex = index">
        <ng-container *ngIf="element.id > 0">{{ element.label }}</ng-container>
        <ng-container *ngIf="element.id <= 0"
          >C{{ (+newestConditionLabel + (rowIndex - newestRowIndex)).toString().padStart(2, '0') }}</ng-container
        >
      </mat-cell>
    </ng-container>

    <!-- this is a repeating container for dynamic columns, repeat for all in propertyNames, because of the *ngFor -->
    <ng-container *ngFor="let disCol of propertyNames; let colIndex = index" matColumnDef="data.{{ disCol }}">
      <mat-header-cell *matHeaderCellDef [ngClass]="{ 'cell-edit-mode': isEditMode }">
        <div class="header-container">
          <ng-container *ngIf="(!isEditMode || unsavedColumns.includes(disCol)) && !(apiService.isLoading$ | async)">{{ disCol }}</ng-container>
          <div *ngIf="isEditMode && !(apiService.isLoading$ | async) && !unsavedColumns.includes(disCol)">
            <div
              class="current-value"
              [matTooltip]="disCol"
              matTooltipPositionAtOrigin="true"
              matTooltipPosition="above"
              [ngClass]="{ modified: disCol != newPropertyNames[disCol] }">
              {{ disCol }}
            </div>
            <div
              class="new-value"
              [ngClass]="{
                empty: !newPropertyNames[disCol],
                modified: disCol != newPropertyNames[disCol],
                'required-field': !newPropertyNames[disCol]
              }">
              <input
                matInput
                class="modifiable-input"
                (focus)="focusRowIdx = -1; focusColIdx = colIndex"
                (blur)="focusRowIdx = -1; focusColIdx = -1"
                [(ngModel)]="newPropertyNames[disCol]"
                (keydown)="onInputKeyDown($event)" />
            </div>
            <ng-container
              #cntSnackbarHeader
              *ngTemplateOutlet="
                tmplSnackbarHeader;
                context: {
                  curVal: disCol,
                  newVal: newPropertyNames[disCol],
                  colName: disCol,
                  rowIndex: 1,
                  colIndex: colIndex
                }
              ">
            </ng-container>
          </div>
          <button
            mat-icon-button
            class="remove-column-btn"
            (click)="removeColumn(disCol)"
            matTooltip="Remove Column"
            *ngIf="isEditMode && unsavedColumns.includes(disCol) && !(apiService.isLoading$ | async)">
            <mat-icon class="material-icons-outlined">indeterminate_check_box</mat-icon>
          </button>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let rowIndex = index" [ngClass]="{ 'cell-edit-mode': isEditMode }">
        <ng-container *ngIf="!isEditMode">{{ element.data[disCol] }}</ng-container>
        <ng-container *ngIf="isEditMode">
          <div
            class="current-value"
            [matTooltip]="element.data[disCol]"
            matTooltipPositionAtOrigin="true"
            matTooltipPosition="above"
            [ngClass]="{ modified: element.data[disCol] != modifiedListData[element.id].data[disCol] }">
            {{ !!element.data[disCol] ? element.data[disCol] : '&nbsp;' }}
          </div>
          <div
            class="new-value"
            [ngClass]="{
              empty: !modifiedListData[element.id].data[disCol],
              modified: element.data[disCol] != modifiedListData[element.id].data[disCol],
              'required-field': !modifiedListData[element.id].data[disCol]
            }">
            <input
              matInput
              class="modifiable-input"
              (focus)="focusRowIdx = rowIndex; focusColIdx = colIndex"
              (blur)="focusRowIdx = -1; focusColIdx = -1"
              [(ngModel)]="modifiedListData[element.id].data[disCol]"
              (keydown)="onInputKeyDown($event)" />
          </div>
          <ng-container
            #cntSnackbar
            *ngTemplateOutlet="
              tmplSnackbar;
              context: {
                curVal: element.data[disCol],
                newVal: modifiedListData[element.id] && modifiedListData[element.id].data[disCol],
                colName: element.label + ' - ' + disCol,
                rowIndex: rowIndex,
                colIndex: colIndex
              }
            ">
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>
    <!-- *************************************** -->

    <ng-container matColumnDef="unevaluable">
      <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isEditMode">
        <span *ngIf="!isEditMode">Unevaluable</span>
        <span *ngIf="isEditMode">
          <button
            mat-icon-button
            class="add-column-btn"
            (click)="addColumn()"
            matTooltip="Add Column"
            *ngIf="isEditMode && !(apiService.isLoading$ | async)">
            <mat-icon class="material-icons-outlined">add_box</mat-icon>
          </button>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="
            !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsReadyToTest') ||
            (currentExperiment | statusIsNot : ['ReadyToTest']) ||
            isEditMode
          "
          [checked]="element.unevaluable"
          (change)="$event ? toggleConditionUnevaluable(element, $event) : null"
          *ngIf="!isEditMode">
        </mat-checkbox>
        <button
          mat-icon-button
          (click)="duplicateRow(modifiedListData[element.id])"
          matTooltip="Duplicate Row"
          *ngIf="isEditMode">
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="removeRow(element)"
          matTooltip="Remove Row"
          *ngIf="isEditMode && element.id < 0">
          <mat-icon>remove</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <div class="header-actions">
          <mat-icon
            *ngIf="!(apiService.isLoading$ | async)"
            class="audit-count-icon"
            [class.disabled]="isEditMode"
            matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[0] }} change log record(s)"
            matBadgeColor="warn"
            (click)="!isEditMode && showColumnAuditDialog()">
            receipt_long
          </mat-icon>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="onEdit(row)" disabled>
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Delete condition and related details"
          (click)="onDelete(row)"
          [disabled]="
            !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsLessThanReadyToTest') ||
            isEditMode ||
            ((currentExperiment | statusIs : ['ReadyToTest']) && listData.data.length == 1)
          ">
          <mat-icon>delete</mat-icon>
        </button>
        <app-note-display
          [experimentId]="experimentId"
          [noteCount]="conditionsNotesCountMap && conditionsNotesCountMap[row.id]"
          [objectID]="row.id"
          [objectName]="objectName"
          [isEditMode]="isEditMode">
        </app-note-display>
        <mat-icon
          *ngIf="conditionsAuditCountMap && conditionsAuditCountMap[row.id] > 0"
          class="audit-count-icon"
          [class.disabled]="isEditMode"
          matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[row.id] }} change log records"
          matBadgeColor="warn"
          (click)="!isEditMode && showAuditDialog(row)">
          receipt_long
        </mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }">
    </mat-footer-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="10"
    showFirstLastButtons
    [style.display]="isEditMode ? 'none' : 'block'">
  </mat-paginator>
  <div class="edit-mode-add-row">
    <button
      mat-raised-button
      aria-label="Append New Row"
      (click)="appendNewRow()"
      *ngIf="isEditMode && !postEditLoading">
      <mat-icon>add</mat-icon>
      Append New Row
    </button>
  </div>
</div>
