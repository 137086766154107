<div *ngIf="isLoading">
  <mat-spinner class="loading-spinner"></mat-spinner>
</div>
<div class="selection-container">
  <div class="selection-primary">
    <button mat-raised-button class="create-config-btn" (click)="onCreateConfiguration()" [disabled]="isLoading || pendingChanges">
      <mat-icon>add</mat-icon>
      Create
    </button>
    <mat-form-field class="assay-form-field">
      <mat-label>Assay</mat-label>
      <mat-select [(value)]="selectedAssayId" (selectionChange)="onAssayIdChange()" [disabled]="isLoading">
        <mat-option *ngFor="let assayId of currentAssayIds" [value]="assayId">
          {{ assayId }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="revision-form-field">
      <mat-label>Revision</mat-label>
      <mat-select [(value)]="selectedRevisionId" (selectionChange)="onRevisionChange()" [disabled]="isLoading">
        <mat-option *ngFor="let revisionId of currentAssayRevisions" [value]="revisionId">
          {{ revisionId == -1 ? '(none)' : revisionId }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-icon-button
      matTooltip="Delete Configuration {{ selectedAssayId }}.{{ selectedRevisionId }}"
      class="delete-btn"
      color="warn"
      (click)="onDeleteConfiguration()"
      *ngIf="selectedAssayId && selectedRevisionId"
      [disabled]="isLoading">
      <mat-icon>delete</mat-icon>
    </button>
    <span class="notification-msg-box" *ngIf="pendingChanges">
      ** Changes currently pending, don't forget to save! **
    </span>
    <div class="edit-actions">
      <button
        mat-raised-button
        class="reset-btn"
        (click)="onRevisionChange()"
        *ngIf="pendingChanges"
        [disabled]="isLoading">
        Reset
      </button>
      <button
        mat-raised-button
        class="save-btn"
        color="primary"
        (click)="saveChanges()"
        *ngIf="pendingChanges"
        [disabled]="isLoading">
        Save
      </button>
    </div>
  </div>
</div>
<div class="configuration-container">
  <div class="chamber-channel-container" *ngIf="selectedAssayId && selectedRevisionId">
    <p-table
      [value]="tableData"
      dataKey="id"
      responsiveLayout="scroll"
      class="config-table"
      (onEditComplete)="onEditComplete($event)">
      <ng-template pTemplate="caption">
        <div class="table-header">
          {{ tableData[0][0].assayName }}
          <mat-icon (click)="onEditName()" matTooltip="Edit the configuration name">edit</mat-icon>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="chamber-column">Chamber</th>
          <th class="channel-{{ idx + 1 }}" *ngFor="let chamberName of chamberNames; let idx = index">
            {{ chamberName }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-config let-rowData let-rowIndex="rowIndex">
        <tr *ngIf="rowIndex != 0">
          <td class="chamber-column">{{ config[0]?.shortName ?? '' }}</td>
          <td
            [pEditableColumn]="rowData[colIdx]"
            [pEditableColumnRowIndex]="colIdx"
            class="channel-{{ colIdx }}"
            *ngFor="let colIdx of editableColumnIndices"
            [pEditableColumnDisabled]="isLoading">
            <p-cellEditor class="editable-cell">
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="config[colIdx].shortName" class="cell-input" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ config[colIdx]?.shortName ?? '' }}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="position-container" *ngIf="selectedAssayId && selectedRevisionId">
    <h2>Column Display Position</h2>
    <div cdkDropList class="config-list" (cdkDropListDropped)="onDropConfig($event)">
      <div class="config-box" *ngFor="let config of positionalConfigurations; let idx = index" cdkDrag>
        Position {{ config.position + 1 }}: {{ config.shortName }} (Chamber {{ config.chamber }}, Channel
        {{ config.channel }})
        <button matSuffix mat-icon-button class="delete-assay-btn" (click)="onClickDeleteAssay(config)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
