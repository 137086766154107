
export const TAB_PAGE_NAMES: string[] = [
    'Samples',
    'Conditions',
    'Logistics',
    'Details'
];

export const PAGE_NAME_EXPERIMENTSLIST = 'ExperimentsList';
export const PAGE_NAME_PRINTRUNSHEET = 'PrintRunSheet';
export const PAGE_NAME_PRINTAVERYSHEET = 'PrintAverySheet';
export const PAGE_NAME_PRINTZEBRASHEET = 'PrintZebraSheet';
export const PAGE_NAME_SAVANNAEXPERIMENTS = 'SavannaExperimentsList';
export const EVENT_IMPORT_SAMPLES = 'ImportSamples';
export const EVENT_APPEND_SAMPLES = 'AppendSamples';
export const EVENT_EDIT_SAMPLES = 'EditSamples';
export const EVENT_RENAME_HEADERS_SAMPLES = 'RenameHeadersSamples';
export const EVENT_IMPORT_CONDITIONS = 'ImportConditions';
export const EVENT_APPEND_CONDITIONS = 'AppendConditions';
export const EVENT_EDIT_CONDITIONS = 'EditConditions';
export const EVENT_RENAME_HEADERS_CONDITIONS = 'RenameHeadersConditions';
export const EVENT_CREATE_LOGISITC = 'CreateLogistic';
export const EVENT_IMPORT_LOGISITCS = 'ImportLogistics';
export const EVENT_APPEND_LOGISITCS = 'AppendLogistics';
export const EVENT_GEN_DETAILS = 'GenerateDetails';
export const EVENT_SCAN_SAMPLE_TUBE_ID = 'ScanSampleTubeID';
export const EVENT_SCAN_DEVICE_ID = 'ScanDeviceID';
export const EVENT_TOGGLE_UNEVALUABLE = 'ToggleUnevaluable';
export const EVENT_TOGGLE_INSTRUMENT_ERROR = 'ToggleInstrumentError';
export const EVENT_ADD_REPLICATE = 'AddReplicate';
export const EVENT_ADD_NOTE = 'AddNote';
export const PAGE_NAME_BUNDLESLIST = 'BundlesList';
export const EVENT_SAVANNARESULTS = 'SavannaResults';
export const EVENT_SAVANNARESULTS_FOUND_DUPLICATES = 'SavannaResultsFoundDuplicates';
export const EVENT_RESULTS_LINK_FUSION = "ViewResultsInFusion";

export const EVENT_CREATE_BARCODE_LINK = 'CreateBarcodeLink';
export const EVENT_VIEW_BARCODE_LINK = 'ViewBarcodeLink';

export const EVENT_CREATE_EXPERIMENT = 'CreateExperiment';
export const EVENT_CLONE_EXPERIMENT = 'CloneExperiment';
export const EVENT_MODIFY_EXPERIMENT = 'ModifyExperiment';
export const EVENT_ARCHIVE_EXPERIMENT = 'ArchiveExperiment';
export const EVENT_UNARCHIVE_EXPERIMENT = 'UnarchiveExperiment';
export const EVENT_CREATE_BUNDLE = 'CreateBundle';
export const EVENT_UPLOAD_FILE = 'FileUpload';
export const EVENT_UPLOAD_FILE_IMPORT = 'UploadImportedFile';
export const EVENT_OPEN_EXPERIMENT = 'OpenExperiment';
export const EVENT_SAVANNA_DOWNLOAD_CSV = 'SavannaDownloadCSV';

export const EVENT_USER_NO_ACCESS = 'UserNoAccess';
export const EVENT_CHANGE_OWNER = 'ChangeOwner';
export const EVENT_USER_VERSION = 'UserLoginVersion';

export const EVENT_STATUS_READY = 'StatusReadyToTest';
export const EVENT_STATUS_COMPLETE = 'StatusComplete';

export const EVENT_TOGGLE_SCAN_DIRECTION = 'ToggleScanDirection';

export const EVENT_USER_VIEW_AUDITS = 'UserViewAudits';

export const EVENT_DELETE_SAMPLE = 'DeleteSample';
export const EVENT_DELETE_CONDITION = 'DeleteCondition';
export const EVENT_DELETE_DETAIL = 'DeleteDetail';
export const EVENT_DELETE_LOGISTIC = 'DeleteLogistic';
export const EVENT_DELETE_UPLOAD = 'DeleteUpload';

export const EVENT_APPLY_COLUMN_FILTER = 'ApplyColumnFilter';

export const EVENT_PRINT_RUN_SHEET = 'PrintRunSheet';
export const EVENT_PRINT_AVERY = 'PrintAvery';
export const EVENT_PRINT_BARCODES = 'PrintBarcodes';

export const EVENT_MULTISELECT_DETAILS = 'MultiselectDetails';

export const EVENT_RESET_TABLE_COLUMNS = 'ResetTableColumns';
