import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { IReplicatePair } from '../changes-dialog/changes-dialog.component';
import { IDetailDisplayItem } from '../detail/detail-list/detail-list.component';
import { IAuditChangeReasons, ILogisticsAuditChangeReasons } from './audit.service.models';
import { ConfigurationService } from './configuration.service';
import {
  AssayName,
  AssayType,
  Bundle,
  Condition,
  Detail,
  DeviceTargetError,
  ErrorUserResponses,
  Experiment,
  FileToUpload,
  IFileToUploadDisplay,
  ImportData,
  InputsHeaderOrder,
  InstrumentError,
  KibanaData,
  Logistic,
  Note,
  NotesCountMap,
  NotesForExperimentCountMap,
  NotesMap,
  Sample,
  SavannaResultsAndConfig,
  User,
  UserAccess,
} from './labpartner.service.model';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class LabpartnerService {
  MAX_RECORDS_PER_IMPORT = 99;

  MAX_REPLICATES_PER_IMPORT = 1000;

  private v1ExperimentBaseURL = 'v1/Experiments';
  private v1SamplesBaseURL = 'v1/Samples';
  private v1LogisticsBaseURL = 'v1/Logistics';
  private v1ConditionsBaseURL = 'v1/Conditions';
  private v1DetailsBaseURL = 'v1/Details';
  private v1UsersBaseURL = 'v1/Users';
  private v1FileUploadURL = 'v1/Upload';
  private v1ImportDataBaseURL = 'v1/ImportData';
  private v1NotesBaseURL = 'v1/Notes';
  private v1BundleBaseURL = 'v1/Bundles';
  private v1SavannaResultsBaseURL = 'v1/SavannaResults';
  private v1InputsBaseURL = 'v1/Inputs';
  private v1BarcodeBaseUrl = 'v1/Barcode';
  private v1ErrorUserResponsesUrl = 'v1/ErrorUserResponses';
  private v1InstrumentErrorsUrl = 'v1/InstrumentErrors';
  private v1DeviceTargetErrorsUrl = 'v1/DeviceTargetErrors';
  private v1AssayNamesBaseUrl = 'v1/AssayNames';


  private apiUrlBase: string;
  private loggedInUser!: number;

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _httpRequestCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isLoading$ = this._isLoading.asObservable();
  httpRequestCount$ = this._httpRequestCount.asObservable();

  get isLoading() {
    return this._isLoading.value || this._httpRequestCount.value > 0;
  }

  constructor(
    private httpClient: HttpClient,
    configService: ConfigurationService,
    private loggingService: LoggingService
  ) {
    const config = configService.getConfig();

    this.apiUrlBase = config.apiServiceEndpoint;
    if (!this.apiUrlBase.endsWith('/')) {
      this.apiUrlBase = `${this.apiUrlBase}/`;
    }
  }

  setIsLoading(value: boolean) {
    setTimeout(() => {
      this._isLoading.next(value);
    }, 0);
  }

  incrementHttpRequestCount(url?: string) {
    if (url) {
      console.log(`HTTP Request (${url})`);
    }
    this._httpRequestCount.next(this._httpRequestCount.value + 1);
  }

  decrementHttpRequestCount(url?: string) {
    if (url) {
      console.log(`HTTP Request Complete (${url})`);
    }
    this._httpRequestCount.next(this._httpRequestCount.value - 1);
  }

  //Set the loggedInUser to be used by all create and update calls
  public setLoggedInUser(userId: number) {
    this.loggedInUser = userId;
    this.loggingService.setUserId(userId);
  }

  public getLoggedInUser(): number {
    return this.loggedInUser;
  }

  // Experiment functions
  public getExperimentsList(includeArchived: boolean = false): Observable<Experiment[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + `/GetAll?includeArchived=${includeArchived}`;
    return this.httpClient.get<Experiment[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getExperiment(id: number): Observable<Experiment> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/' + id;
    return this.httpClient.get<Experiment>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createExperiment(experiment: Experiment): Observable<Experiment> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/Create';
    let today = new Date();
    experiment.status = 1;
    experiment.dateCreated = today;
    experiment.dateUpdated = today;
    experiment.createdBy = this.loggedInUser;
    console.log(
      'create experiment :' +
      experiment.name +
      ' replicates :' +
      experiment.replicates +
      ' date : ' +
      experiment.dateCreated +
      ' url ' +
      url
    );

    return this.httpClient.post<Experiment>(url, experiment).pipe(finalize(() => this.setIsLoading(false)));
  }

  public archiveExperiment(experimentId: number, batchReason: string | undefined): Observable<Experiment> {
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/Archive';

    return this.httpClient.post<Experiment>(url, {
      experimentId: experimentId,
      batchReason: batchReason
    });
  }

  public unarchiveExperiment(experimentId: string, batchReason: string | undefined): Observable<Experiment> {
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/Unarchive';

    return this.httpClient.post<Experiment>(url, {
      experimentId: experimentId,
      batchReason: batchReason
    });
  }

  changeExperimentOwner(newOwnerId: number, experimentId: number) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + `/ChangeOwner/${experimentId}/${newOwnerId}`;
    return this.httpClient.put<Experiment>(url, null).pipe(finalize(() => this.setIsLoading(false)));
  }

  public updateExperiment(
    modifiedExperiment: Experiment,
    batchReason: string,
    fieldReasons: { [key: string]: string }
  ): Observable<Experiment> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/Update';
    return this.httpClient
      .patch<Experiment>(url, {
        experimentId: modifiedExperiment.experimentId,
        modifiedExperiment,
        batchReason,
        fieldReasons,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setStatus(id: number, status: number) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/SetStatus/' + id + '/' + status;
    return this.httpClient.put(url, null, { observe: 'response' }).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Samples functions
  public getSamplesList(key: string, keyId: number): Observable<Sample[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1SamplesBaseURL + '/' + key + '/' + keyId;
    return this.httpClient.get<Sample[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getInputsHeaderOrder(experimentId: number, inputType: number): Observable<InputsHeaderOrder> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InputsBaseURL + '/InputsHeaderOrder/' + experimentId + '/' + inputType;
    return this.httpClient.get<InputsHeaderOrder>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  deleteSample(experimentId: number, sampleId: number, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1SamplesBaseURL + '/DeleteSample';
    return this.httpClient
      .delete(url, {
        body: {
          experimentId: experimentId,
          targetId: sampleId,
          batchReason: batchReason,
        },
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setSampleUnevaluableWithReasonAsync(sample: Sample, batchReason: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1SamplesBaseURL + '/SetSampleUnevaluable';
    return this.httpClient
      .put(url, { ...sample, batchReason })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  submitSampleChangesWithAuditingAsync(
    experimentId: number,
    replicatePairs: IReplicatePair[],
    newSamples: Sample[],
    modifiedSamples: Sample[],
    renamedHeaders: any,
    batchReason: string,
    fieldReasons: string[],
    headersOrder: string
  ): Promise<Sample[] | undefined> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1SamplesBaseURL + '/SampleUpdatesWithAuditing';
    return this.httpClient
      .patch<Sample[]>(url, {
        experimentId,
        replicatePairs,
        newSamples,
        modifiedSamples,
        renamedHeaders,
        batchReason,
        fieldReasons,
        headersOrder
      })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  // Logistics functions
  public getLogisticsList(key: string, keyId: number): Observable<Logistic> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1LogisticsBaseURL + '/' + key + '/' + keyId;
    return this.httpClient.get<Logistic>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  createLogisticKeyValue(experimentId: number, key: string, value: string, batchReason?: string): Observable<Logistic> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1LogisticsBaseURL + '/CreateKeyValue';
    return this.httpClient
      .patch<Logistic>(url, {
        experimentId: experimentId,
        key: key,
        value: value,
        batchreason: batchReason,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  createMultipleLogistics(
    isAppend: boolean,
    experimentId: number,
    logistic: Logistic,
    batchReason?: string
  ): Observable<Logistic> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1LogisticsBaseURL + '/CreateMultiple';
    return this.httpClient
      .post<Logistic>(url, {
        isAppend: isAppend,
        experimentId: experimentId,
        logistic: logistic,
        batchReason: batchReason,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  deleteLogisticKeyValue(experimentId: number, key: string, value: string, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1LogisticsBaseURL + '/DeleteKeyValue';
    return this.httpClient
      .delete<Logistic>(url, {
        body: {
          experimentId: experimentId,
          key: key,
          value: value,
          batchReason: batchReason,
        },
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  updateLogistic(logistic: Logistic, logisticsAuditChangeReasons?: ILogisticsAuditChangeReasons): Observable<Logistic> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1LogisticsBaseURL + '/Update';
    return this.httpClient
      .put<Logistic>(url, {
        logistic,
        logisticsAuditChangeReasons,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  // Note functions
  createNote(
    experimentId: number,
    objectId: number,
    objectName: string,
    noteTitle: string,
    noteText: string
  ): Observable<Note> {
    this.setIsLoading(true);
    return this.httpClient
      .post<Note>(`${this.apiUrlBase}${this.v1NotesBaseURL}/Create`, {
        experimentId,
        objectId,
        objectName,
        noteTitle,
        noteText,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  createMultipleNotes(experimentId: number, detailIds: number[], noteTitle: string, noteText: string) {
    this.setIsLoading(true);
    return this.httpClient
      .post(
        `${this.apiUrlBase}${this.v1NotesBaseURL}/CreateMultipleNotesOnDetails`,
        {
          experimentId,
          detailIds,
          noteTitle,
          noteText,
        },
        { observe: 'response' }
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  public getNotesList(key: string, keyId: number, objectName: string): Observable<Note[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1NotesBaseURL + '/' + key + '/' + keyId + '/' + objectName;
    return this.httpClient.get<Note[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  getNoteCountsForAllExperiments(): Observable<NotesCountMap> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1NotesBaseURL}/GetCountsForAllExperiments?includeArchived=false`;
    return this.httpClient.get<NotesCountMap>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  getNoteCountsByExperimentId(experimentId: number): Observable<NotesForExperimentCountMap> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1NotesBaseURL}/GetCountsByExperimentId?experimentId=${experimentId}`;
    return this.httpClient.get<NotesForExperimentCountMap>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  getNoteByExperimentId(experimentId: number): Observable<NotesMap> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1NotesBaseURL}/GetByExperimentId?experimentId=${experimentId}`;
    return this.httpClient.get<NotesMap>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Condtion functions
  public getConditionsList(key: string, keyId: number): Observable<Condition[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ConditionsBaseURL + '/' + key + '/' + keyId;
    return this.httpClient.get<Condition[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  deleteCondition(experimentId: number, conditionId: number, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ConditionsBaseURL + '/DeleteCondition';
    return this.httpClient
      .delete(url, {
        body: {
          experimentId: experimentId,
          targetId: conditionId,
          batchReason: batchReason,
        },
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setConditionUnevaluableWithReasonAsync(condition: Condition, batchReason: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ConditionsBaseURL + '/SetConditionUnevaluable';
    return this.httpClient
      .put(url, { ...condition, batchReason })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  submitConditionChangesWithAuditingAsync(
    experimentId: number,
    replicatePairs: IReplicatePair[],
    newConditions: Condition[],
    modifiedConditions: Condition[],
    renamedHeaders: any,
    batchReason: string,
    fieldReasons: string[],
    headersOrder: string
  ): Promise<Condition[] | undefined> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ConditionsBaseURL + '/ConditionUpdatesWithAuditing';
    return this.httpClient
      .patch<Condition[]>(url, {
        experimentId,
        replicatePairs,
        newConditions,
        modifiedConditions,
        renamedHeaders,
        batchReason,
        fieldReasons,
        headersOrder
      })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  // Import Data functions
  public createImportData(importData: ImportData): Observable<ImportData> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ImportDataBaseURL + '/Create';
    let today = new Date();
    importData.dateCreated = today;
    importData.createdBy = this.loggedInUser;
    console.log('create import data header:' + importData.importHeader);
    return this.httpClient.post<ImportData>(url, importData).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getImportData(key: string, keyId: number): Observable<ImportData[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ImportDataBaseURL + '/' + key + '/' + keyId;
    return this.httpClient.get<ImportData[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Details functions
  getDetailById(detailId: number): Observable<Detail> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + '/' + detailId;
    return this.httpClient
      .get<Detail>(url)
      .pipe(
        tap(d => {
          // DataEntryErrorFlag is being replaced with Unevaluable
          delete (d as any).dataEntryErrorFlag;
          return d;
        })
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  getDetailsByIds(detailIds: number[]): Observable<Detail[]> {
    this.setIsLoading(true);
    return this.httpClient
      .post<Detail[]>(`${this.apiUrlBase}${this.v1DetailsBaseURL}/GetByIds`, detailIds)
      .pipe(
        tap(d => {
          // DataEntryErrorFlag is being replaced with Unevaluable
          delete (d as any).dataEntryErrorFlag;
          return d;
        })
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  getDetailsList(key: string, keyId: number): Observable<IDetailDisplayItem[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + '/' + key + '/' + keyId;
    return this.httpClient
      .get<IDetailDisplayItem[]>(url)
      .pipe(
        tap(x =>
          x.forEach(d => {
            // DataEntryErrorFlag is being replaced with Unevaluable
            delete (d as any).dataEntryErrorFlag;
            return d;
          })
        )
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  getDetailCountByExperimentId(experimentId: number): Observable<number> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1DetailsBaseURL}/GetRecordCountByExperimentId?experimentId=${experimentId}`;
    return this.httpClient.get<number>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  getExperimentCountForExperimentId(experimentId: number, deviceTypeCode: string): Observable<KibanaData> {
    const url = `${this.apiUrlBase}${this.v1DetailsBaseURL}/GetExperimentCountByExperimentId?experimentId=${experimentId}&deviceTypeCode=${deviceTypeCode}`;
    return this.httpClient.get<KibanaData>(url);
  }

  generateDetails(id: number, replicatePair: IReplicatePair[]): Observable<number> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + '/' + id;
    return this.httpClient.post<number>(url, replicatePair).pipe(finalize(() => this.setIsLoading(false)));
  }

  deleteDetail(experimentId: number, detailId: number, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + '/DeleteDetail';
    return this.httpClient
      .delete(url, {
        body: {
          experimentId: experimentId,
          targetId: detailId,
          batchReason: batchReason,
        },
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setDetailUnevaluableWithReasonAsync(detail: Detail, batchReason: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + '/SetDetailUnevaluable';
    return this.httpClient
      .put(url, { ...detail, batchReason })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  setMultipleDetailsUnevaluable(experimentId: number, detailIds: number[], unevaluable: boolean, batchReason: string) {
    this.setIsLoading(true);
    return this.httpClient
      .patch(
        `${this.apiUrlBase}${this.v1DetailsBaseURL}/SetMultipleDetailsUnevaluable`,
        {
          experimentId,
          detailIds,
          unevaluable,
          batchReason,
        },
        { observe: 'response' }
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setSampleTubeIDAsync(detailId: number, sampleTubeId: string, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + `/SetSampleTubeID/${detailId}`;
    return this.httpClient
      .post(url, <Partial<Detail>>{ sampleTubeID: sampleTubeId.trim(), batchReason })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  setMultipleSampleTubeIds(experimentId: number, detailIds: number[], sampleTubeId: string, batchReason: string) {
    this.setIsLoading(true);
    return this.httpClient
      .patch(
        `${this.apiUrlBase}${this.v1DetailsBaseURL}/SetMultipleSampleTubeIds`,
        {
          experimentId,
          detailIds,
          sampleTubeId,
          batchReason,
        },
        { observe: 'response' }
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  setDeviceIDAsync(detailId: number, deviceId: string, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DetailsBaseURL + `/SetDeviceID/${detailId}`;
    return this.httpClient
      .post(url, <Partial<Detail>>{ deviceID: deviceId.trim(), batchReason })
      .pipe(finalize(() => this.setIsLoading(false)))
      .toPromise();
  }

  setMultipleDeviceIds(experimentId: number, detailIds: number[], deviceId: string, batchReason: string) {
    this.setIsLoading(true);
    return this.httpClient
      .patch(
        `${this.apiUrlBase}${this.v1DetailsBaseURL}/SetMultipleDeviceIds`,
        {
          experimentId,
          detailIds,
          deviceId,
          batchReason,
        },
        { observe: 'response' }
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  addReplicate(experimentId: number, baseDetailId: number): Observable<IDetailDisplayItem> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1DetailsBaseURL}/AddReplicate`;
    return this.httpClient
      .post<IDetailDisplayItem>(url, {
        experimentId,
        detailId: baseDetailId,
      })
      .pipe(
        tap(x => {
          // DataEntryErrorFlag is being replaced with Unevaluable
          delete (x as any).dataEntryErrorFlag;
          return x;
        })
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  addMultipleReplicates(experimentId: number, baseDetailIds: number[]): Observable<IDetailDisplayItem[]> {
    this.setIsLoading(true);
    const url = `${this.apiUrlBase}${this.v1DetailsBaseURL}/AddMultipleReplicates`;
    return this.httpClient
      .post<IDetailDisplayItem[]>(url, {
        experimentId,
        detailIds: baseDetailIds,
      })
      .pipe(
        tap(x => {
          x.forEach(() => {
            // DataEntryErrorFlag is being replaced with Unevaluable
            delete (x as any).dataEntryErrorFlag;
          });
          return x;
        })
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }



  // Bundle functions
  public getBundlesList(): Observable<Bundle[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/GetAll';
    return this.httpClient
      .get<Bundle[]>(url)
      .pipe(
        tap(res => {
          res.forEach(b => {
            b.startDate = new Date(b.startDate);
            b.endDate = new Date(b.endDate);
          });
        })
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  public deleteBundle(bundle: Bundle) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/' + bundle.bundleId;
    return this.httpClient.delete(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createSerialNumbersBundle(bundle: Bundle): Observable<Bundle> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/Create';
    const today = new Date();
    bundle.createdBy = this.loggedInUser;
    bundle.dateCreated = today;
    console.log('create bundle :' + bundle.name + ' date : ' + bundle.dateCreated + ' url ' + url);

    return this.httpClient.post<Bundle>(url, bundle).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createExperimentIdsBundle(bundle: Bundle): Observable<Bundle> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/Create';
    const today = new Date();
    const minimumDate = new Date(0);
    bundle.createdBy = this.loggedInUser;
    bundle.startDate = minimumDate;
    bundle.endDate = minimumDate;
    bundle.dateCreated = today;
    console.log('create bundle :' + bundle.name + ' date : ' + bundle.dateCreated + ' url ' + url);

    return this.httpClient.post<Bundle>(url, bundle).pipe(finalize(() => this.setIsLoading(false)));
  }

  public updateBundle(id: number, bundle: Bundle): Observable<Bundle> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/Update';
    let today = new Date();

    bundle.bundleId = id;
    bundle.dateCreated = today;
    console.log('updating bundle :' + bundle.name + ' ' + bundle.dateCreated + ' url ' + url);
    return this.httpClient.put<Bundle>(url, bundle).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getAssayTypes(): Observable<AssayType[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1BundleBaseURL + '/AssayTypes';

    return this.httpClient.get<AssayType[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Upload functions
  uploadFile(file: FileToUpload): Observable<any> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1FileUploadURL;
    return this.httpClient.post<any>(url, file).pipe(finalize(() => this.setIsLoading(false)));
  }

  deleteUpload(experimentId: number, uploadId: number, batchReason?: string) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1FileUploadURL + '/DeleteUpload';
    return this.httpClient
      .delete(url, {
        body: {
          experimentId: experimentId,
          targetId: uploadId,
          batchReason: batchReason,
        },
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  downloadFile(file: FileToUpload): Observable<any> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1FileUploadURL + '/DownloadFile';
    return this.httpClient.post<any>(url, file).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getUploadsList(key: string, keyId: number): Observable<IFileToUploadDisplay[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1FileUploadURL + '/' + key + '/' + keyId;
    return this.httpClient.get<IFileToUploadDisplay[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // User functions
  public getUserByAccountId(keyId: string): Observable<UserAccess> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1UsersBaseURL + '/' + keyId;
    return this.httpClient.get<UserAccess>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createUser(user: User): Observable<User> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1UsersBaseURL + '/Create';
    console.log('create user :' + user.displayName);
    return this.httpClient.post<User>(url, user).pipe(finalize(() => this.setIsLoading(false)));
  }

  public getUsersList(): Observable<User[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1UsersBaseURL + '/GetAll';
    return this.httpClient.get<User[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public updateUser(user: User): Observable<User> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1UsersBaseURL + '/Update';
    return this.httpClient.put<User>(url, user).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Savanna Results functions
  public getSavannaResults(experimentPrefix: string): Observable<SavannaResultsAndConfig> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1SavannaResultsBaseURL + `/${experimentPrefix}`;
    return this.httpClient.get<SavannaResultsAndConfig>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Inputs
  createMultipleSamplesAndConditions(
    experimentId: number,
    importFileData: FileToUpload,
    samples: Sample[],
    conditions: Condition[],
    samplesImportData?: ImportData,
    conditionsImportData?: ImportData
  ) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InputsBaseURL + `/CreateMultipleSamplesAndConditions/${experimentId}`;
    return this.httpClient
      .post<{ samples: Sample[]; conditions: Condition[] }>(url, {
        samples,
        samplesImportData,
        conditions,
        conditionsImportData,
        importFileData,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  appendMultipleSamplesAndConditions(
    experimentId: number,
    samples: Sample[],
    conditions: Condition[],
    replicatePairs: IReplicatePair[],
    samplesImportData?: ImportData,
    conditionsImportData?: ImportData,
    auditChangeReasons?: IAuditChangeReasons
  ) {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InputsBaseURL + `/AppendMultipleSamplesAndConditions`;
    return this.httpClient
      .post<{ samples: Sample[]; conditions: Condition[] }>(url, {
        experimentId,
        samples,
        conditions,
        replicatePairs,
        samplesImportData,
        conditionsImportData,
        auditChangeReasons,
      })
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  validateSamplesAndConditions(experimentId: number): Observable<boolean> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/ValidateSamplesAndConditions/' + experimentId;
    return this.httpClient.get<boolean>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  cloneExperiment(experimentId: number): Observable<Experiment> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ExperimentBaseURL + '/Clone/' + experimentId;
    return this.httpClient.post<Experiment>(url, null).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Barcode functions
  getBarcodesByIdentifier(experimentId: number, linkIdentifier: string) {
    this.setIsLoading(true);
    return this.httpClient
      .get<{ readableBarcodes: string[]; deviceType: number; isExpired: boolean }>(
        `${this.apiUrlBase}${this.v1BarcodeBaseUrl}/GetBarcodesByIdentifier?experimentId=${experimentId}&linkIdentifier=${linkIdentifier}`
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  generateBarcodeLinkIdentifier(experimentId: number, detailIds?: number[]) {
    this.setIsLoading(true);
    return this.httpClient
      .post<{ linkIdentifier: string; expirationDate: Date }>(
        `${this.apiUrlBase}${this.v1BarcodeBaseUrl}/GenerateBarcodeLink`,
        {
          experimentId,
          detailIds: detailIds ?? [],
        }
      )
      .pipe(finalize(() => this.setIsLoading(false)));
  }

  getBarcodeByBarcodeId(barcodeId: string){
    this.setIsLoading(true);
    return this.httpClient
      .get(`${this.apiUrlBase}${this.v1BarcodeBaseUrl}/DataMatrix/${barcodeId}`,{responseType:'arraybuffer'})
      .pipe(finalize(() => this.setIsLoading(false)));
  }
  //Utility function
  public padLeadingZeros(num: Number, size: number): string {
    var s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  detailsDefaultSort(list: Detail[]) {
    // sort them in SAMPLES, CONDITIONS, REPLICATE # order
    // can easily change this to be based on IDs not Names, even though those aren't what's printed
    if (list == null) return;
    list = list.sort((prev, cur) => this.detailsDefaultSortCallback(prev, cur));
    return list;
  }

  detailsDefaultSortCallback(prev: Detail, cur: Detail) {
    // SAMPLES first
    if (prev.sampleLabel < cur.sampleLabel) {
      return -1;
    } else if (prev.sampleLabel > cur.sampleLabel) {
      return 1;
    } else {
      // then CONDITIONS
      if (prev.conditionLabel < cur.conditionLabel) {
        return -1;
      } else if (prev.conditionLabel > cur.conditionLabel) {
        return 1;
      } else {
        // then REPLICATE #
        if (prev.replicateNo < cur.replicateNo) {
          return -1;
        } else if (prev.replicateNo > cur.replicateNo) {
          return 1;
        }
      }
    }

    return 0;
  }

  public formatDateToDateAndTime(dt: Date): string {
    let ret = `${this.toStringWithLeadingZeros(dt.getFullYear(), 4)}`;
    ret += `-${this.toStringWithLeadingZeros(dt.getMonth() + 1, 2)}`;
    ret += `-${this.toStringWithLeadingZeros(dt.getDate(), 2)}`;

    ret += `-${this.toStringWithLeadingZeros(dt.getHours(), 2)}`;
    ret += `-${this.toStringWithLeadingZeros(dt.getMinutes(), 2)}`;
    ret += `-${this.toStringWithLeadingZeros(dt.getSeconds(), 2)}`;

    return ret;
  }

  public toStringWithLeadingZeros(n: number, numZeros: number): string {
    return n.toString().padStart(numZeros, '0');
  }

  // Error User Responses

  public getErroUserResponses(): Observable<ErrorUserResponses[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1ErrorUserResponsesUrl + `/GetAll`;
    return this.httpClient.get<ErrorUserResponses[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Instrument Errors

  public getInstrumentErrorsByExperimentId(experimentId: number): Observable<InstrumentError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InstrumentErrorsUrl + `/GetByExperimentId/${experimentId}`;
    return this.httpClient.get<InstrumentError[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createOrUpdateMultipleInstrumentErrors(body: any): Observable<InstrumentError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InstrumentErrorsUrl + `/CreateOrUpdateMultiple`;
    return this.httpClient.post<InstrumentError[]>(url, body).pipe(finalize(() => this.setIsLoading(false)));
  }

  public deleteMultipleInstrumentErrors(body: any): Observable<InstrumentError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1InstrumentErrorsUrl + `/DeleteMultiple`;
    return this.httpClient.post<InstrumentError[]>(url, body).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Device Target Errors

  public getDeviceTargetErrorsByExperimentId(experimentId: number): Observable<DeviceTargetError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DeviceTargetErrorsUrl + `/GetByExperimentId/${experimentId}`;
    return this.httpClient.get<DeviceTargetError[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }

  public createOrUpdateMultipleDeviceTargetErrors(body: any): Observable<DeviceTargetError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DeviceTargetErrorsUrl + `/CreateOrUpdateMultiple`;
    return this.httpClient.post<DeviceTargetError[]>(url, body).pipe(finalize(() => this.setIsLoading(false)));
  }

  public deleteMultipleDeviceTargetErrors(body: any): Observable<DeviceTargetError[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1DeviceTargetErrorsUrl + `/DeleteMultiple`;
    return this.httpClient.post<DeviceTargetError[]>(url, body).pipe(finalize(() => this.setIsLoading(false)));
  }

  // Assay Names 

  public getAssayNames(onlyActives: boolean): Observable<AssayName[]> {
    this.setIsLoading(true);
    const url = this.apiUrlBase + this.v1AssayNamesBaseUrl + `/GetAll?onlyActives=${onlyActives}`;
    return this.httpClient.get<AssayName[]>(url).pipe(finalize(() => this.setIsLoading(false)));
  }
}
