<div>
  <div class="search-div">
    Details and Results for Experiment # {{ currentExperimentId }}

    <span class="p-input-icon-right">
      <input
        type="text"
        pInputText
        placeholder="Search"
        autocomplete="off"
        [(ngModel)]="searchKey"
        (ngModelChange)="onSearchChanged($event)" />
      <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
        <i class="pi pi-times" *ngIf="searchKey"></i>
      </span>
    </span>

    <mat-slide-toggle [(ngModel)]="hideEmptyResults" color="primary" (change)="hideResultsChanged()">
      Hide Empty Results
    </mat-slide-toggle>

    <button mat-raised-button [matMenuTriggerFor]="menuExportTargets" [disabled]="isLoading">Export</button>
    <mat-menu #menuExportTargets="matMenu">
      <button
        mat-menu-item
        (click)="downloadCsvFile(false)"
        matTooltip="Click to download data as CSV"
        *ngIf="currentExperimentId != 0">
        All records
      </button>
      <button
        mat-menu-item
        (click)="downloadCsvFile(true)"
        matTooltip="Click to download invalid analytes as CSV"
        *ngIf="currentExperimentId != 0">
        Invalid Analytes
      </button>
    </mat-menu>

    <mat-form-field name="assay-layout" class="assay-layout-form-field" *ngIf="currentExperimentId != 0">
      <mat-label for="assay-layout">Assay Layout</mat-label>
      <mat-select
        [(ngModel)]="selectedAssayIdRevision"
        (selectionChange)="initializeColumns(true)"
        matBadge="{{ assayIdRevisionGroups.length }}"
        [matBadgeHidden]="assayIdRevisionGroups.length <= 1">
        <mat-option *ngFor="let assayIdRevision of assayIdRevisionGroups" [value]="assayIdRevision.value">
          {{ assayIdRevision.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <p-multiSelect
      class="column-selector"
      [options]="columnVisibilityOptions"
      [(ngModel)]="selectedColumns"
      optionLabel="header"
      selectedItemsLabel="{0} columns selected"
      [style]="{ 'min-width': '200px' }"
      placeholder="Choose Columns"
      [displaySelectedLabel]="false"
      (onPanelShow)="onColumnSelectionShow($event)"
      (onChange)="onColumnSelectionChange($event)"
      (onPanelHide)="onColumnSelectionHide($event)"
      *ngIf="primeTable?.columns && !isLoading">
    </p-multiSelect>

    <button mat-raised-button (click)="resetTable()" matTooltip="Click to reset table to default state">
      Reset Table
    </button>

    <button matSuffix mat-icon-button class="btn-refresh" (click)="refreshResults()">
      <mat-icon>refresh</mat-icon>
    </button>

    <div class="labpartner-experiment-link">
      <a routerLink="/labpartner" [queryParams]="{ experimentid: currentExperimentId }">View LabPartner Data</a>
    </div>
  </div>

  <p-table
    styleClass="savanna-results-tbl"
    dataKey="result.assayId"
    [value]="tableData"
    [columns]="selectedColumns"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [scrollable]="true"
    [paginator]="true"
    (onPage)="paginate($event)"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    scrollHeight="flex"
    sortMode="single"
    [customSort]="true"
    (sortFunction)="customSort($event)"
    [loading]="isLoading"
    (onColReorder)="onColumnReorder($event)"
    (onColResize)="onColumnResize($event)" 
    paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
      <tr *ngIf="!isLoading">
        <th
          class="freezable-column"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          pResizableColumn
          pReorderableColumn
          pFrozenColumn
          [frozen]="col.parentField == 'assay' ? false : frozenMode">
          <ng-container *ngIf="col.parentField != 'assay'">
            {{ col.header }} <p-sortIcon class="sort-icon" [field]="col.field"></p-sortIcon>
          </ng-container>
          <ng-container *ngIf="col.parentField == 'assay'">
            {{ col.header }} <p-sortIcon class="sort-icon" [field]="col.header"></p-sortIcon>
            <br />
            <ng-container *ngIf="columnCounts[col.header]">
              {{ columnCounts[col.header][RESULT_POSITIVE] }} / {{ columnCounts[col.header][RESULT_NEGATIVE] }} /
              {{ columnCounts[col.header][RESULT_INVALID] }} / {{ columnCounts[col.header][RESULT_UNEVALUABLE] }}
            </ng-container>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td
          class="freezable-column"
          *ngFor="let col of columns"
          pFrozenColumn
          [frozen]="col.parentField == 'assay' ? false : frozenMode">
          <ng-container *ngIf="col.parentField != 'assay'">
            <ng-container *ngIf="col.field == 'barcodeID'; else tmpInstrumentError">
              <ng-container *ngIf="currentExperiment?.status != VerbedExperimentStatus.IsDetailsGenerated">
                {{ rowData.detail.barcodeID }}
              </ng-container>
              <mat-icon
                *ngIf="rowData.hasDuplicateResults"
                class="duplicate-warning"
                matTooltip="Duplicate results found (showing most recent). Download CSV to see details of other results"
                matBadge="{{ rowData.otherResults.length + 1 }}">
                warning
              </mat-icon>
              <mat-icon
                *ngIf="rowData.result?.isAssayIdAndRevisionOutlier"
                class="assay-revision-mismatch"
                matTooltip="Assay Id ({{ rowData.result?.assayId }})
                and Revision ({{ rowData.result?.assayRevision }}) do not match the majority of the other results.">
                report
              </mat-icon>
            </ng-container>
            <ng-template #tmpInstrumentError>
              <ng-container *ngIf="col.field == 'instrumentError'; else tmpUnevalData">
                <mat-checkbox
                  class="instrument-error-checkbox"
                  [disabled]="
                    (currentExperiment | statusIsNot : ['ReadyToTest']) ||
                    (rowData.detail.unevaluable && !rowData.detail.instrumentError)
                  "
                  [checked]="rowData.detail.instrumentError"
                  (change)="$event ? toggleDetailInstrumentError(rowData, $event) : null">
                </mat-checkbox>
              </ng-container>
            </ng-template>
            <ng-template #tmpUnevalData>
              <ng-container *ngIf="col.field == 'unevaluable'; else tmpNotes">
                <mat-checkbox
                  class="unevaluable-checkbox"
                  [disabled]="
                    (currentExperiment | statusIsNot : ['ReadyToTest']) ||
                    rowData.detail.sampleUnevaluable ||
                    rowData.detail.conditionUnevaluable ||
                    (rowData.detail.instrumentError && !rowData.detail.unevaluable)
                  "
                  [checked]="rowData.detail.unevaluable || rowData.detail.sampleUnevaluable || rowData.detail.conditionUnevaluable"
                  (change)="
                    $event ? toggleDetailUnevaluable(rowData, $event) : null">
                  </mat-checkbox>
              </ng-container>
            </ng-template>
            <ng-template #tmpNotes>
              <ng-container *ngIf="col.field == 'notes'; else tmplNotBarcodeId">
                <app-note-display
                  [experimentId]="currentExperimentId"
                  [noteCount]="detailsNotesCountMap && detailsNotesCountMap[rowData.detail.detailId]"
                  [hasLiveNote]="detailsLiveNotesCountMap && detailsLiveNotesCountMap[rowData.detail.detailId] > 0"
                  [objectID]="rowData.detail.detailId"
                  [objectName]="'Detail'"
                  [experimentDetail]="rowData.detail">
                </app-note-display>
              </ng-container>
            </ng-template>
            <ng-template #tmplNotBarcodeId>
              {{ rowData[col.parentField] && rowData[col.parentField][col.field] }}
            </ng-template>
          </ng-container>

          <ng-container *ngIf="col.parentField == 'assay'">
            <ng-container
              *ngIf="
                rowData.result != null &&
                rowData.result?.assayId == selectedAssayIdRevision?.assayId &&
                rowData.result?.assayRevision == selectedAssayIdRevision?.assayRevision &&
                rowData.result.shortNameTargetMap &&
                rowData.result.shortNameTargetMap[col.field]
              ">
              <ng-container *ngIf="validateCurrentLayout(rowData.result)">
                <ng-container [ngSwitch]="rowData.result.shortNameTargetMap[col.field].qualitativeResult">
                  <ng-container *ngSwitchCase="'Positive'" [ngTemplateOutlet]="tmplPositiveResult"></ng-container>
                  <ng-container *ngSwitchCase="'Negative'" [ngTemplateOutlet]="tmplNegativeResult"></ng-container>
                  <ng-container *ngSwitchCase="'Invalid'" [ngTemplateOutlet]="tmplInvalidResult"></ng-container>
                </ng-container>
                <mat-icon
                  *ngIf="rowData.result.shortNameTargetMap[col.field].isUnevaluable"
                  class="unevaluable-warning  unevaluable-badge" matTooltip="Unevaluable">
                  warning
                </mat-icon>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="rowData.result == null && (rowData.detail.instrumentError || (rowData.targetError && columnHasUnevaluable(rowData,col.field)))">
              <mat-icon
                class="unevaluable-warning" matTooltip="Unevaluable">
                warning
              </mat-icon>
            </ng-container>
            
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #tmplPositiveResult>
    <img class="result-images" src="/assets/images/result-positive.png" />
  </ng-template>

  <ng-template #tmplNegativeResult>
    <img class="result-images" src="/assets/images/result-negative.png" />
  </ng-template>

  <ng-template #tmplInvalidResult>
    <img class="result-images" src="/assets/images/result-invalid.png" />
  </ng-template>
</div>

<div [style.visibility]="'hidden'">
  <ng-container [ngTemplateOutlet]="tmplPositiveResult"></ng-container>
  <ng-container [ngTemplateOutlet]="tmplNegativeResult"></ng-container>
  <ng-container [ngTemplateOutlet]="tmplInvalidResult"></ng-container>
</div>

<div class="noExperimentWarning" *ngIf="currentExperimentId == 0">
  Please choose an experiment from the Experiments tab.
</div>
