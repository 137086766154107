import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

export enum BarcodesTemplates{
  AVERY_SHEET,
  BARCODES,
  DISPLAY
}
@Component({
  selector: 'app-barcode-print',
  templateUrl: './barcode-print.component.html',
  styleUrls: ['./barcode-print.component.scss']
})
export class BarcodePrintComponent implements OnChanges {
  BarcodesTemplates = BarcodesTemplates;
  
  @Input() templateType:BarcodesTemplates = BarcodesTemplates.AVERY_SHEET
  @Input() barcodeData!: string;
  @Input() labelsPerRow: number = 0;
  @Output() onLoaded: EventEmitter<boolean> = new EventEmitter();
  
  public barcodeID: string = "barcode";
  public upperLabel: string = "E0001";
  public lowerLabel: string = "S01-C01-R01";
  public base64Image: string = "";

  constructor(
    public configService: ConfigurationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.barcodeID = this.barcodeData;
    this.loadBarcodeImage();
    this.upperLabel = this.barcodeData.slice(0, this.barcodeData.indexOf('_'));
    this.lowerLabel = this.barcodeData.slice(this.barcodeData.indexOf('_') + 1);
  }

  ngOnInit(): void {
  }


  loadBarcodeImage() {
    this.sendRequestWithRetries()
      .then((base64Image: any) => {
        this.base64Image = base64Image;
        this.onLoaded.emit(true);
      })
      .catch((error) => {
        this.onLoaded.emit(false);
      });
  }

  sendRequestWithRetries(retries = 3) {
    return new Promise((resolve, reject) => {
      const attemptRequest = (attempt: number) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(xhr.response);
          } else {
            if (attempt <= retries) {
              attemptRequest(attempt + 1);
            } else {
              reject(new Error('Failed after 3 retries'));
            }
          }
        };

        xhr.onerror = () => {
          if (attempt <= retries) {
            attemptRequest(attempt + 1);
          } else {
            reject(new Error('Failed after 3 retries'));
          }
        };

        let url = `${this.configService.getConfig().apiServiceEndpoint}v1/Barcode/DataMatrix/${this.barcodeID}`;
        if(this.templateType === BarcodesTemplates.BARCODES){
          url += '?pixelSize=4'
        }
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      };

      attemptRequest(1);
    });
  }
}
