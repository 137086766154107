import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SampleService } from 'src/app/shared/sample.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  Condition,
  CreatedStatus,
  DetailsGeneratedStatus,
  Experiment,
  ExperimentType,
  InputsHeaderOrder,
  ReadyToTestStatus,
  StudyType,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { UserAccountService } from 'src/app/services/user-account.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { HttpStatusCode } from '@angular/common/http';
import { IChangeSet, IReplicatePair } from 'src/app/changes-dialog/changes-dialog.component';
import { AuditObjectIdCountMap, AuditObjectName } from 'src/app/services/audit.service.models';
import { LoggingService } from 'src/app/services/logging.service';
import { EVENT_CREATE_CONDITIONS_NEW_FLOW, EVENT_DELETE_CONDITION, EVENT_EDIT_CONDITIONS, EVENT_RENAME_HEADERS_CONDITIONS } from 'src/app/services/logging-constants';
import { DetailsService } from 'src/app/services/details.service';
import { AddColumnDialogComponent, IAddColumnResult } from 'src/app/add-column-dialog/add-column-dialog.component';
import { SampleListComponent } from 'src/app/samples/sample-list/sample-list.component';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { INPUT_TYPES } from 'src/app/services/labpartner-constants';

interface KeyboardEventInHtmlInput extends KeyboardEvent {
  target: HTMLInputElement;
}

export interface IConditionDisplayItem {
  [key: string]: any;
  id: number;
  conditionId: number;
  label: string;
  name: string;
  unevaluable: boolean;
  data: { [key: string]: any };
}

@Component({
  selector: 'app-condition-list',
  templateUrl: './condition-list.component.html',
  styleUrls: ['./condition-list.component.scss'],
})
export class ConditionListComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() experimentId: number = 0;
  @Input() conditionsNotesCountMap?: { [key: number]: number };
  @Input() conditionsAuditCountMap?: AuditObjectIdCountMap;
  @Input() samplesListCmp?: SampleListComponent;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @Output() isEditing: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  modifiedListData: { [key: number]: IConditionDisplayItem } = {};
  listData!: MatTableDataSource<IConditionDisplayItem>;
  objectName = 'Condition';

  isEditMode: boolean = false;
  postEditLoading: boolean = false;
  newestRowIndex: number = 0;
  newestConditionLabel: string = '';
  focusColIdx: number = -1;
  focusRowIdx: number = -1;
  prevPageSize: number = 0;
  unsavedColumns: string[] = [];

  ReadyToTest = ReadyToTestStatus;
  StudyType = StudyType;

  headers: string[] = [];

  lodColumns: any[] = [
    { prop: 'cartridgeLot', header: 'Cartridge Lot', required: true }
  ];

  generalColumns: any[] = [
    { prop: 'name', header: 'Name', required: true },
    { prop: 'cartridgeLot', header: 'Cartridge Lot' }

  ];

  dailyExternalControlsColumns: any[] = [
    { prop: 'cartridgeLot', header: 'Cartridge Lot', required: true },
    { prop: 'testingDate', header: 'TestingDate', required: true, isCustom: true, type: 'date' },
  ];

  staticColumns: any[] = [];

  private MAX_RECORDS_PER_IMPORT = this.labPartnerService.MAX_RECORDS_PER_IMPORT;

  public displayedColumns!: string[];
  // material table mat-header-cell and mat-cell requires only the property name (not the full property path)
  public propertyNames!: string[];

  public newPropertyNames: any = {};
  // material table mat-header-row requires the full property path (e.g. data.temperature) so we build those up separately
  public propertyPaths!: string[];

  public searchKey: string = '';

  constructor(
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private service: SampleService,
    private detailsService: DetailsService,
    private matDialog: MatDialog,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private labPartnerService: LabpartnerService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFixedColumns();
  }

  ngOnInit() {
    this.apiService.getConditionsList('experimentId', this.experimentId).subscribe((conditions: Condition[]) => {
      this.listData = new MatTableDataSource();

      const displayItems = this.parseConditionsToConditionDisplayItems(conditions);
      this.listData.data.push(...displayItems);
      this.parseConditionFromAPI();

      // to build this from the keys collection, have to prepend data in front of each property
      // start with the known column, then spread the rest in the array
      this.loadHeaderOrder();

      this.setListDataConfig();
    });

    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  loadHeaderOrder() {
    this.apiService.getInputsHeaderOrder(this.experimentId, INPUT_TYPES.CONDITIONS).subscribe((res: InputsHeaderOrder) => {
      try {
        let headersJson = JSON.parse(res.headers);
        this.headers = Object.keys(headersJson).map((x, i) => headersJson[i]);
      }
      catch (ex) {
        this.headers = this.parsePropertyNames();
      }
      this.refreshPropertyPathsNamesAndDisplayedColumns();
      this.resetHeadersChanges();
    });
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    if (this.listData != null) {
      this.listData.filter = this.searchKey.trim().toLowerCase();
    }
  }

  onCreate() {
    this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
  }

  onEdit(row: any) {
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
  }

  onDelete(row: any) {
    const conditionId = row.id;
    if (this.appState.GetCurrentExperiment().status == DetailsGeneratedStatus) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to delete this condition, all associated details will be deleted as well ?'
      );

      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.deleteCondition(conditionId, row);
        }
      });
    } else {
      this.deleteCondition(conditionId, row);
    }
  }

  async toggleConditionUnevaluable(condition: IConditionDisplayItem, evt: MatCheckboxChange) {
    const currentExperiment = this.appState.GetCurrentExperiment();

    const prevValue = condition.unevaluable;
    const newValue = !condition.unevaluable;

    condition.experimentId = currentExperiment.experimentId;
    condition.unevaluable = !condition.unevaluable;

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      [
        {
          identifier: `Condition ${condition.label} ${condition.name}`,
          field: 'Unevaluable',
          oldValue: prevValue ?? false,
          newValue: newValue,
        },
      ],
      this.appState.GetCurrentExperiment().type,
      {}
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || currentExperiment.type == ExperimentType.ResearchAndDevelopment)
      ) {
        await this.setConditionUnevaluableWithExceptionHandling(
          (condition as any),
          data.batchReason ?? '',
          evt.source,
          prevValue ?? false
        );
        this.appState.SetExperimentDataChanged(this.experimentId);
      } else {
        condition.unevaluable = !condition.unevaluable;
        evt.source.checked = prevValue ?? false;
      }
    });
  }

  onInputKeyDown(evt: KeyboardEvent): void {
    const kbEvt = evt as KeyboardEventInHtmlInput;

    let targetNum = 0;
    const currentNum = +kbEvt.target.id.slice(kbEvt.target.id.lastIndexOf('-') + 1);
    const userIsNotSelecting = kbEvt.target.selectionStart == kbEvt.target.selectionEnd;

    switch (kbEvt.key) {
      case 'ArrowLeft':
        if (kbEvt.target.selectionStart == 0 && userIsNotSelecting) {
          targetNum = currentNum - 1;
        }
        break;
      case 'ArrowRight':
        if (kbEvt.target.selectionStart == kbEvt.target.value.length && userIsNotSelecting) {
          targetNum = currentNum + 1;
        }
        break;
      case 'ArrowUp':
        targetNum = currentNum - this.propertyNames.length;
        break;
      case 'ArrowDown':
        targetNum = currentNum + this.propertyNames.length;
        break;
    }

    if (targetNum != 0) {
      const newTarget = document.getElementById(`mat-input-${targetNum}`) as HTMLInputElement;

      if (newTarget && newTarget.classList.contains('modifiable-input')) {
        setTimeout(() => {
          newTarget.focus();
          newTarget.setSelectionRange(0, newTarget.value.length);
        });
      }
    }
  }

  async onConditionsEdit(): Promise<void> {
    this.prevPageSize = this.listData.paginator!.pageSize;
    this.paginator._changePageSize(this.listData.data.length)
    this.paginator.firstPage();

    if (this.listData.data.length > 0) {
      this.newestRowIndex = this.listData.data.length - 1;
      this.newestConditionLabel = this.listData.data
        .filter(x => x.id > 0)
        .map(x => x.label)
        .sort()
      [this.listData.data.length - 1].slice(1);
    }

    this.isEditing.emit(true);
    this.isEditMode = true;
    this.modifiedListData = {};
    for (let listItem of this.listData.data) {
      this.modifiedListData[listItem.id] = JSON.parse(JSON.stringify(listItem));
    }
  }

  async onConditionsEditCommit(): Promise<void> {
    let temporalRemoved: any = {};
    let newPropertyNamesReversed: any = {};
    Object.keys(this.newPropertyNames).forEach(propertyName => {
      newPropertyNamesReversed[this.newPropertyNames[propertyName]] = propertyName
    });

    let temporalModifiedListData = JSON.parse(JSON.stringify(this.modifiedListData));
    let newHeaders = [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])];
    let oldHeaders = [...this.headers, ...this.unsavedColumns];

    if (new Set(newHeaders).size !== newHeaders.length) {
      this.notificationService.error('Duplicated column.');
      return;
    }

    this.propertyNames.forEach(propertyName => {
      var newPropertyName = this.newPropertyNames[propertyName];
      if (propertyName != newPropertyName) {
        temporalRemoved[propertyName] = newPropertyName;
        for (let condition of this.listData.data) {
          this.modifiedListData[condition.id].data[newPropertyName] = temporalModifiedListData[condition.id].data[propertyName];
          if (!newHeaders.find(x => x == propertyName)) {
            delete this.modifiedListData[condition.id].data[propertyName];
          }
        }
      }
    });

    for (let sample of this.listData.data) {
      var tempObj: any = {};
      this.propertyNames.forEach(propertyName => {
        var newPropertyName = this.newPropertyNames[propertyName];
        tempObj[newPropertyName] = this.modifiedListData[sample.id].data[newPropertyName]
      });
      this.modifiedListData[sample.id].data = tempObj;
    }

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    let changeSet: IChangeSet[] = [];
    let modifiedConditions: Condition[] = [];
    let newConditions: Condition[] = [];

    const newRows = Object.keys(this.modifiedListData).filter(x => +x <= 0);
    const newRowKeys: number[] = newRows.map(x => +x);
    let newRowCount: number = newRowKeys.length;

    for (let newRowKey of newRowKeys) {
      const missingValues = Object.values(this.modifiedListData[newRowKey].data)
        .filter((x: string) => !!!x)
        .map((x: string) => x);

      if (missingValues.length > 0) {
        this.notificationService.error('All fields are required.');
        return;
      }

      // Check if fixed columns values has changed
      for (let i = 0; i < this.staticColumns.length; i++) {
        let col = this.staticColumns[i];

        if (col.required && !this.modifiedListData[newRowKey][col.prop]) {
          this.notificationService.error('All fields are required.');
          return;
        }
      }
    }

    for (let newColKey of this.unsavedColumns) {
      for (let conditionId of Object.keys(this.modifiedListData)) {
        const colValueMissing = !!!this.modifiedListData[+conditionId].data[this.newPropertyNames[newColKey]];

        if (colValueMissing) {
          this.notificationService.error('All fields are required.');
          return;
        }
      }
    }

    let numberOfModifiedCells = 0;

    for (let originalItem of this.listData.data) {
      const originalItemData = originalItem.data;
      const modifiedItemData = this.modifiedListData[originalItem.id].data;

      // Check if fixed columns values has changed
      let hasChanges = false;
      if (originalItem.id > 0 && (this.currentExperiment?.studyType != StudyType.ImportExcel)) {
        // Check if fixed columns values has changed
        for (let i = 0; i < this.staticColumns.length; i++) {
          let col = this.staticColumns[i];

          if (col.required && !this.modifiedListData[originalItem.id][col.prop]) {
            this.notificationService.error('All fields are required.');
            return;
          }

          if (originalItem[col.prop] != this.modifiedListData[originalItem.id][col.prop]) {
            changeSet.push({
              identifier: `Condition ${originalItem.label}`,
              field: col.prop,
              oldValue: originalItem[col.prop],
              newValue: this.modifiedListData[originalItem.id][col.prop]
            });
            if (!hasChanges) {
              hasChanges = true;
              let modifiedItem: any = {
                conditionId: originalItem.conditionId,
                experimentId: this.experimentId,
                name: this.modifiedListData[originalItem.id].name,
                importData: JSON.stringify(modifiedItemData)
              };
              this.staticColumns.forEach((col) => {
                modifiedItem[col.prop] = this.modifiedListData[originalItem.id][col.prop];
              });
              modifiedConditions.push(modifiedItem);
            }

          }
        }
      }
      if (originalItem.id > 0 && JSON.stringify(originalItemData) != JSON.stringify(modifiedItemData)) {
        if (!hasChanges) {
          let modifiedItem: any = {
            conditionId: originalItem.conditionId,
            experimentId: this.experimentId,
            name: this.modifiedListData[originalItem.id].name,
            importData: JSON.stringify(modifiedItemData),
          }
          // Add fixed columns values
          this.staticColumns.forEach((col) => {
            modifiedItem[col.prop] = this.modifiedListData[originalItem.id][col.prop];
          });
          modifiedConditions.push(modifiedItem);
        }

        for (let field of Object.keys(modifiedItemData)) {
          if (originalItemData[field] != modifiedItemData[field] &&
            (field == this.newPropertyNames[field]) || this.unsavedColumns.find(x => x === field) || this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
            if (!!!modifiedItemData[field]) {
              this.notificationService.error('All fields are required.');
              return;
            }

            numberOfModifiedCells++;

            changeSet.push({
              identifier: `Condition ${originalItem.label}`,
              field: field,
              oldValue: originalItemData[field],
              newValue: modifiedItemData[field],
              data: JSON.stringify(modifiedItemData),
            });
          }

          if (originalItemData[newPropertyNamesReversed[field]] != modifiedItemData[field] &&
            (field != this.newPropertyNames[field]) && !this.unsavedColumns.find(x => x === field) && !this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
            changeSet.push({
              identifier: `Condition ${originalItem.label}`,
              field: newPropertyNamesReversed[field],
              oldValue: originalItemData[newPropertyNamesReversed[field]],
              newValue: modifiedItemData[field],
              data: JSON.stringify(modifiedItemData),
            });
          }
        }
      }

      if (originalItem.id <= 0) {
        let newItem: any = {
          conditionId: 0,
          experimentId: this.experimentId,
          name: this.modifiedListData[originalItem.id].name,
          importData: JSON.stringify(modifiedItemData),
        }
        // Add fixed columns values
        this.staticColumns.forEach((col) => {
          newItem[col.prop] = this.modifiedListData[originalItem.id][col.prop];
        });
        newConditions.push(newItem);
      }
    }

    Object.keys(this.newPropertyNames).forEach(p => {
      if (this.newPropertyNames[p] != p && !this.unsavedColumns.find(x => x === p)) {
        changeSet.push({
          identifier: AuditObjectName.Samples,
          field: 'Header Renamed',
          oldValue: p,
          newValue: this.newPropertyNames[p],
        });
      }
    });

    if (newRowCount > 0) {
      changeSet.push({
        identifier: AuditObjectName.Conditions,
        field: '-',
        oldValue: '-',
        newValue: `+ ${newRowCount} new rows`,
      });
    }

    let replicatePairs: IReplicatePair[] = [];

    newConditions
      .sort((a, b) => b.conditionId - a.conditionId)
      .forEach((condition, index) => {
        condition.label = `C${(+this.newestConditionLabel + (Math.abs(index) + 1)).toString().padStart(2, '0')}`;
      });

    // New combinations with new Conditions as base
    if (this.samplesListCmp?.listData.data) {
      for (let condition of newConditions) {
        for (let sample of this.samplesListCmp?.listData.data) {
          replicatePairs.push({
            sampleId: sample.sampleId,
            sampleLabel: sample.label,
            conditionId: condition.conditionId,
            conditionLabel: condition.label,
            numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
          });
        }
      }
    }

    if (changeSet.length == 0) {
      this.restoreData(temporalRemoved, temporalModifiedListData, oldHeaders);
      return;
    }

    if(this.currentExperiment?.status == CreatedStatus) {
      replicatePairs = [];
    }
    
    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      {
        multiReason: true,
        isAppend: true,
        defaultReplicates: this.appState.GetCurrentExperiment().replicates,
        replicateData: replicatePairs,
      }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        let errorDuringEdit = false;

        // Makes "Loading data..." display again without breaking other type safety
        let copyListData = Object.assign([], this.listData.data);
        (this.listData as any) = undefined;
        this.postEditLoading = true;

        try {
          let renamedHeaders: any = {};
          Object
            .keys(this.newPropertyNames)
            .filter(p => p !== this.newPropertyNames[p] && !this.unsavedColumns.find(x => x == p))
            .forEach(p => {
              renamedHeaders[p] = this.newPropertyNames[p];
            });

          let headersOrder: any = {};
          [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])].forEach((x, i) => {
            headersOrder[i] = x;
          });

          // Parse values before sending to API
          this.parseConditionToAPI([...newConditions, ...modifiedConditions])

          const refreshedConditions = await this.apiService.submitConditionChangesWithAuditingAsync(
            this.experimentId,
            replicatePairs.filter(rp => !rp.ignore && (rp.numberOfReplicates ?? 0) > 0),
            newConditions,
            modifiedConditions,
            renamedHeaders,
            data?.batchReason ?? '',
            data.fieldReasons,
            JSON.stringify(headersOrder)
          );

          if (!refreshedConditions) { throw new Error("refreshedConditions is null"); }

          const headersProps: { [key: string]: number | string } = {
            ExperimentId: this.experimentId,
            RenamedHeadersCount: Object.keys(renamedHeaders).length,
            DeviceType: this.appState.GetCurrentExperiment().deviceType,
            ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          };
          this.loggingService.logEvent(EVENT_RENAME_HEADERS_CONDITIONS, headersProps);

          if (this.currentExperiment?.studyType != null && this.currentExperiment?.studyType != StudyType.ImportExcel) {
            let orignalNumberOfConditions = copyListData.filter((x: any) => x.id > 0).length;

            if (orignalNumberOfConditions === 0) {
              const newConditionsProps: { [key: string]: number | string } = {
                ExperimentId: this.experimentId,
                DeviceType: this.appState.GetCurrentExperiment().deviceType,
                ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
                NumberCreated: copyListData.filter((x: any) => x.id <= 0).length,
                StudyType: this.currentExperiment?.studyType,
              };
              this.loggingService.logEvent(EVENT_CREATE_CONDITIONS_NEW_FLOW, newConditionsProps);
            }
          } else {
            const props: { [key: string]: number | string } = {
              ExperimentId: this.experimentId,
              AppendConditionCount: newConditions.length,
              UpdateConditionCount: modifiedConditions.length,
              ChangedConditionCellCount: numberOfModifiedCells,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_EDIT_CONDITIONS, props);
          }

          this.setNewListData(this.parseConditionsToConditionDisplayItems(refreshedConditions));
          this.refreshPropertyPathsNamesAndDisplayedColumns();
          this.resetHeadersChanges();

          this.loadHeaderOrder();
          this.appState.SetExperimentDataChanged(this.experimentId);
        } catch (err: any) {
          errorDuringEdit = true;
          const expectedErr = err.error as AuditedChangeResponse;
          if (expectedErr.expectedException) {
            this.notificationService.error(expectedErr.message);
          } else {
            throw err;
          }
        }

        this.endEditMode(errorDuringEdit);
      } else {
        this.restoreData(temporalRemoved, temporalModifiedListData, oldHeaders);
      }
    });
  }

  restoreData(temporalRemoved: any, temporalModifiedListData: any, oldHeaders: string[]) {
    Object.keys(temporalRemoved).forEach(propertyName => {
      var newPropertyName = temporalRemoved[propertyName];
      for (let condition of this.listData.data) {
        this.modifiedListData[condition.id].data[propertyName] = this.modifiedListData[condition.id].data[newPropertyName];
        if (!oldHeaders.find(x => x == newPropertyName)) {
          delete this.modifiedListData[condition.id].data[newPropertyName];
        }
      }
    });
    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();
    Object.keys(temporalRemoved).forEach(propertyName => {
      this.newPropertyNames[propertyName] = temporalRemoved[propertyName]
    });
  }

  appendNewRow(conditionItem?: IConditionDisplayItem): void {
    if (this.listData.data.length >= this.MAX_RECORDS_PER_IMPORT) {
      this.notificationService.warn('Maximum Condition records is ' + this.MAX_RECORDS_PER_IMPORT);
      return;
    }

    const curModifiedListItems = Object.keys(this.modifiedListData).sort((a, b) => +a - +b);
    let newestRealItem = this.modifiedListData[+curModifiedListItems[curModifiedListItems.length - 1]];
    if (!newestRealItem) {
      newestRealItem = { data: {} } as any;
    }
    const firstItem = this.modifiedListData[+curModifiedListItems[0]];

    const newestAppendedItem = firstItem && firstItem.id < 0 ? firstItem : null;
    const newId = newestAppendedItem ? newestAppendedItem.id - 1 : -1;

    const newData: { [key: string]: any } = {};
    const newBlankData: { [key: string]: any } = {};
    for (let prop of Object.keys(newestRealItem.data)) {
      newBlankData[prop] = '';
      if (conditionItem) {
        newData[prop] = conditionItem.data[prop];
      }
    }

    const newItem = <IConditionDisplayItem>{
      id: newId,
      label: `NewRow`,
      name: conditionItem ? conditionItem.name : `NewRow`,
      conditionId: newId,
      unevaluable: false,
      data: conditionItem ? newData : newBlankData,
    };

    // Add fixed columns values
    this.staticColumns.forEach((col) => {
      newItem[col.prop] = conditionItem ? conditionItem[col.prop] : ``;
    });

    this.modifiedListData[newId] = {
      ...newItem,
      data: { ...(conditionItem ? newData : newBlankData) },
    };

    this.listData.data.push({
      ...newItem,
      data: { ...newBlankData },
    });

    this.paginator!._changePageSize(this.listData.data.length);
    this.setNewListData(this.listData.data);
  }

  addColumn() {
    const dialogRef = this.matDialog.open<AddColumnDialogComponent, any, IAddColumnResult>(AddColumnDialogComponent, {
      width: '300px',
      panelClass: 'add-column-dialog-container',
      disableClose: false,
      position: { top: '10%' },
    });

    let currentHeadersNames = [...this.headers, ...this.unsavedColumns];
    let newHeadersNames = [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])];
    dialogRef.afterClosed().subscribe(result => {
      if (result?.columnName) {
        if (currentHeadersNames.find(x => x == result.columnName) || newHeadersNames.find(x => x == result.columnName)) {
          this.notificationService.error('Column duplicated.');
          return;
        }
        this.unsavedColumns.push(result.columnName);

        this.refreshPropertyPathsNamesAndDisplayedColumns();

        this.setListDataConfig();
      }
    });
  }

  removeColumn(colName: string) {
    for (let condition of this.listData.data) {
      delete condition.data[colName];
      delete this.modifiedListData[condition.id].data[colName];
    }

    this.unsavedColumns = this.unsavedColumns.filter(uc => uc != colName);

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    this.setListDataConfig();
  }

  duplicateRow(conditionItem: IConditionDisplayItem): void {
    this.appendNewRow(conditionItem);
  }

  removeRow(conditionItem: IConditionDisplayItem): void {
    delete this.modifiedListData[conditionItem.id];
    this.setNewListData(this.listData.data.filter(x => x.id != conditionItem.id));
  }

  endEditMode(errorOccurred: boolean = false): void {
    this.isEditing.emit(false);
    this.isEditMode = false;
    this.postEditLoading = false;
    this.modifiedListData = {};

    this.unsavedColumns = [];

    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();

    if (errorOccurred) {
      this.ngOnInit();
    } else {
      const dataToKeep = this.listData.data.filter(d => d.id > 0);
      this.setNewListData(dataToKeep);

      setTimeout(() => {
        this.paginator!._changePageSize(this.prevPageSize);
        this.paginator!.firstPage();
      });
    }
  }

  showColumnAuditDialog() {
    this.dialogService.openChangelogDialog(
      `Change log for Conditions`,
      this.experimentId,
      AuditObjectName.Conditions,
      0,
      undefined,
      true
    );
  }

  showAuditDialog(condition: IConditionDisplayItem) {
    this.dialogService.openChangelogDialog(
      `Change log for Condition ${condition.label} - ${condition.name}`,
      this.experimentId,
      AuditObjectName.Conditions,
      condition.conditionId
    );
  }

  private refreshPropertyPathsNamesAndDisplayedColumns() {
    this.propertyPaths = this.parsePropertyPaths();

    this.displayedColumns = this.propertyPaths;

    // these will be only the property names, without the 'data.' prefix
    this.propertyNames = [...this.headers, ...this.unsavedColumns]
    this.propertyNames.forEach((p: string) => {
      if (!this.newPropertyNames[p]) {
        this.newPropertyNames[p] = p;
      }
    });
  }

  private resetHeadersChanges() {
    this.newPropertyNames = {};
    this.propertyNames.forEach((p: string) => {
      this.newPropertyNames[p] = p;
    });
  }

  private parsePropertyPaths() {
    let propertyPaths = [
      'label',
      ...this.headers.map(x => `data.${x}`),
      ...this.unsavedColumns.map(x => `data.${x}`),
      'unevaluable',
      'actions',
    ];
    // Add fixed columns values
    propertyPaths.push(...this.staticColumns.map(x => x.prop));

    return propertyPaths;
  }

  private parsePropertyNames() {
    if (this.listData.data.length == 0) {
      return [];
    }

    let maxPropIndex = 0;
    let maxPropCount = 0;
    for (let i = 0; i < this.listData.data.length; i++) {
      const curPropCount = Object.keys(this.listData.data[i].data).length;
      if (maxPropCount < curPropCount) {
        maxPropCount = curPropCount;
        maxPropIndex = i;
      }
    }
    return Object.keys(this.listData.data[maxPropIndex].data);
  }

  private parseConditionsToConditionDisplayItems(conditions: Condition[]): IConditionDisplayItem[] {
    const conditionDisplayItems: IConditionDisplayItem[] = [];
    for (let condition of conditions) {
      let displayItem = <IConditionDisplayItem>{
        id: condition.conditionId,
        conditionId: condition.conditionId,
        label: condition.label,
        name: condition.name,
        unevaluable: condition.unevaluable,
        createdBy: condition.createdBy,
        dateCreated: condition.dateCreated,
        data: JSON.parse(condition.importData),
      };
      // Add fixed columns values
      this.staticColumns.forEach((col) => {
        displayItem[col.prop] = (condition as any)[col.prop];
      });
      conditionDisplayItems.push(displayItem);
    }
    return conditionDisplayItems;
  }

  private setNewListData(data: IConditionDisplayItem[]) {
    this.listData = new MatTableDataSource(data);
    this.parseConditionFromAPI();
    this.setListDataConfig();
    if (this.table) {
      this.table.renderRows();
    }
  }

  private setListDataConfig() {
    if (this.listData != null) {
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;

      this.listData.filterPredicate = (data, filter) => {
        return this.displayedColumns.some(ele => {
          if (ele == 'actions') {
            return false;
          }

          let parts = ele.split('.');
          let columnData = undefined;

          if (parts.length > 1) {
            columnData = data.data[parts[1]].toString();
          } else {
            columnData = data[ele].toString();
          }

          return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
        });
      };
    }
  }

  private async setConditionUnevaluableWithExceptionHandling(
    condition: Condition,
    batchReason: string,
    evtSource: MatCheckbox,
    prevValue: boolean
  ) {
    try {
      return await this.apiService.setConditionUnevaluableWithReasonAsync(condition, batchReason);
    } catch (err: any) {
      condition.unevaluable = !condition.unevaluable;
      evtSource.checked = prevValue;
      if (err.status == HttpStatusCode.BadRequest && err.error.expectedException) {
        this.notificationService.error(err.error.message);
      } else {
        throw err;
      }
      return;
    }
  }

  private deleteCondition(conditionId: number, row: IConditionDisplayItem) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Condition ${row.label}`,
      field: 'Entire Row',
      oldValue: `Condition ${row.label}`,
      newValue: 'DELETED',
    });

    const relatedDetails = this.detailsService.getCurrentDetails().filter(d => d.conditionId == conditionId);
    if (relatedDetails && relatedDetails.length > 0) {
      changeSet.push({
        identifier: `Detail${relatedDetails.length > 1 ? 's' : ''}`,
        field: `${relatedDetails.length > 1 ? 'Multiple Rows' : 'Entire Row'}`,
        oldValue: `${relatedDetails.length} Detail Row${relatedDetails.length > 1 ? 's' : ''}`,
        newValue: `DELETED ${relatedDetails.length} related Detail${relatedDetails.length > 1 ? 's' : ''}`,
      });
    }

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteCondition(this.experimentId, conditionId, data.batchReason).subscribe(
          () => {
            const index = this.listData.data?.indexOf(row, 0);
            if (index != null && index > -1) {
              this.listData.data.splice(index, 1);
            }

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            this.notificationService.success('Condition deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedConditionId: conditionId,
              ConditionLabel: row.label,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_DELETE_CONDITION, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }
  /**
   * Set the fixed columns based on the current experiment
   */
  setFixedColumns() {
    if (this.currentExperiment && (this.currentExperiment.studyType == StudyType.LoDConfirmation
      || this.currentExperiment.studyType == StudyType.LoDRangeFinding)) {
      this.staticColumns = Object.assign([], this.lodColumns);
    }
    else if (this.currentExperiment && this.currentExperiment.studyType == StudyType.DailyExternalControls) {
      this.staticColumns = Object.assign([], this.dailyExternalControlsColumns);
    }
    else if (this.currentExperiment && this.currentExperiment.studyType == StudyType.General) {
      this.staticColumns = Object.assign([], this.generalColumns);
    }
  }

  /**
   * Parse contidion data from the table to send to the API
   * @param conditions the conditions to parse 
   */
  parseConditionToAPI(conditions: Condition[]) {
    conditions.forEach((condition: any) => {
      this.staticColumns.forEach((col) => {
        if (col.isCustom && col.type === 'boolean') {
          if (condition[col.prop] === 'false') {
            condition[col.prop] = false;
          }
          if (condition[col.prop] === 'true') {
            condition[col.prop] = true;
          }
        }
      });
    });
  }

  /**
   * Parse condition data from API to display in the table
   */
  parseConditionFromAPI() {
    this.listData.data.forEach((condition: IConditionDisplayItem) => {
      this.staticColumns.forEach((col) => {
        if (col.isCustom && col.type === 'boolean') {
          if (condition[col.prop] === false) {
            condition[col.prop] = 'false';
          }
          if (condition[col.prop] === true) {
            condition[col.prop] = 'true';
          }
        }
        if (col.isCustom && col.type === 'date') {
          if (condition[col.prop] && condition[col.prop].includes('/')) {
            var splitted = condition[col.prop].split(' ')[0].split('/');
            condition[col.prop] = `${splitted[2]}-${splitted[0]}-${splitted[1]}`;
          }
        }
      });
    });
  }

  /**
   *  Get the paged data from the filtered data
   * @returns the paged data from the filtered data
   */
  getFilteredAndPagedData() {
    if (!this.listData || !this.paginator) return [];

    const filteredData = this.listData.filteredData;

    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    // Get the paged data from the filtered data
    const pagedData = filteredData.slice(startIndex, endIndex);

    return pagedData;
  }
}
