<div class="device-target-error-dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary">
    <div>Unevaluable Data</div>
  </mat-toolbar>
</div>

<form [formGroup]="deviceTargetErrorForm" class="normal-form" (submit)="onSubmit(deviceTargetErrorForm.value)">
  <div mat-dialog-content class="device-target-error-dialog-content">
    <div class="device-target-error-controls-group">
      <div class="unevaluable-data-controls-message">
        Select the next checkbox only if the {{ deviceName === 'Savanna' ? 'entire cartridge' : 'entire device' }} is unevaluable:
      </div>
      <mat-checkbox (change)="onEntireCartridgeChange()" color="accent" [(ngModel)]="errorTargets[0].isActive"
        [ngModelOptions]="{standalone: true}" [disabled]="isLoading || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
        {{ deviceName === 'Savanna' ? 'Entire cartridge' : 'Entire device' }} 
      </mat-checkbox>

      <ng-container *ngIf="deviceName === 'Savanna'">
        <div class="unevaluable-data-controls-message">
          Select the chamber(s)/channel(s) which is/are unevaluable:
        </div>
        <div style="display: flex;flex-wrap: wrap;padding-left: 30px;">
          <div *ngFor="let chamber of CHAMBERS_ARRAY; let iChamber = index;" style="flex:0 0 50%;">
            <mat-checkbox [disabled]="isLoading || errorTargets[(iChamber * CHAMBERS_NUM) + 1].disabled || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"
              (change)="onChamberChange(iChamber + 1, $event.checked)" color="accent"
              [(ngModel)]="errorTargets[(iChamber * CHAMBERS_NUM) + 1].isActive" [ngModelOptions]="{standalone: true}">
              Whole chamber 0{{iChamber + 1}} is unevaluable
            </mat-checkbox>
            <div *ngFor="let channel of CHANNELS_ARRAY; let iChannel = index;" style="padding-left: 30px;">
              <mat-checkbox [disabled]="isLoading || errorTargets[(iChamber * CHAMBERS_NUM) + 2 + iChannel].disabled || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"
                (change)="onChannelChange(iChamber + 1)" color="accent"
                [(ngModel)]="errorTargets[(iChamber * CHAMBERS_NUM) + 2 + iChannel].isActive"
                [ngModelOptions]="{standalone: true}">
                Chamber 0{{iChamber + 1}} - Channel 0{{iChannel + 1}}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="p-default-form-field p-float-label bottom-border-only p-static-float">
        <textarea pInputText id="error-text" formControlName="errorText" maxlength="2000">
        </textarea>
        <label for="error-text">Error text</label>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="device-target-error-dialog-actions">
    <button mat-raised-button color="primary" type="button" *ngIf="hasError" (click)="onRemove()" [disabled]="isLoading || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
      Remove Unevaluable Data
    </button>
    <button *ngIf="hasError" color="primary" mat-icon-button class="experiment-edit-icon" type="button"
      matTooltip="This option is to remove the Unevaluable Data check and delete the info saved previously, use it only in case you had recorded an Unevaluable Data mistakenly.">
      <mat-icon>help</mat-icon>
    </button>
    <div class="separator"></div>
    <button mat-raised-button color="warn" type="button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
    <button mat-raised-button color="primary" type="submit"
      [disabled]="deviceTargetErrorForm.invalid || isLoading || !hasErrorTargets() || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
      Submit
    </button>
  </div>
</form>