import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SampleService } from 'src/app/shared/sample.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  DetailsGeneratedStatus,
  Experiment,
  ExperimentType,
  InputsHeaderOrder,
  ReadyToTestStatus,
  Sample,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { HttpStatusCode } from '@angular/common/http';
import { NotificationService } from 'src/app/shared/notification.service';
import { IChangeSet, IReplicatePair } from 'src/app/changes-dialog/changes-dialog.component';
import { AuditObjectIdCountMap, AuditObjectName } from 'src/app/services/audit.service.models';
import { EVENT_DELETE_SAMPLE, EVENT_EDIT_SAMPLES, EVENT_RENAME_HEADERS_SAMPLES } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { DetailsService } from 'src/app/services/details.service';
import { AddColumnDialogComponent, IAddColumnResult } from 'src/app/add-column-dialog/add-column-dialog.component';
import { ConditionListComponent } from 'src/app/conditions/condition-list/condition-list.component';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { INPUT_TYPES } from 'src/app/services/labpartner-constants';

interface KeyboardEventInHtmlInput extends KeyboardEvent {
  target: HTMLInputElement;
}

export interface ISampleDisplayItem {
  [key: string]: any;
  id: number;
  sampleId: number;
  label: string;
  name: string;
  unevaluable: boolean;
  data: { [key: string]: any };
}

@Component({
  selector: 'app-sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: ['./sample-list.component.scss'],
})
export class SampleListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() experimentId: number = 0;
  @Input() samplesNotesCountMap?: { [key: number]: number };
  @Input() samplesAuditCountMap?: AuditObjectIdCountMap;
  @Input() conditionsListCmp?: ConditionListComponent;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @Output() isEditing: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  modifiedListData: { [key: number]: ISampleDisplayItem } = {};
  listData!: MatTableDataSource<ISampleDisplayItem>;
  objectName = 'Samples';

  isEditMode: boolean = false;
  postEditLoading: boolean = false;
  newestRowIndex: number = 0;
  newestSampleLabel: string = '';
  focusColIdx: number = -1;
  focusRowIdx: number = -1;
  prevPageSize: number = 0;
  unsavedColumns: string[] = [];

  ReadyToTest = ReadyToTestStatus;

  headers: string[] = [];

  private MAX_RECORDS_PER_IMPORT = this.labPartnerService.MAX_RECORDS_PER_IMPORT;

  public displayedColumns!: string[];
  // material table mat-header-cell and mat-cell requires only the property name (not the full property path)
  public propertyNames!: string[];

  public newPropertyNames: any = {};
  // material table mat-header-row requires the full property path (e.g. data.temperature) so we build those up separately
  public propertyPaths!: string[];

  public searchKey: string = '';

  constructor(
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private service: SampleService,
    private detailsService: DetailsService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private labPartnerService: LabpartnerService
  ) {
    super();
  }

  ngOnInit() {
    this.apiService.getSamplesList('experimentId', this.experimentId).subscribe((samples: Sample[]) => {
      this.listData = new MatTableDataSource();

      const displayItems = this.parseSamplesToSampleDisplayItems(samples);
      this.listData.data.push(...displayItems);

      // to build this from the keys collection, have to prepend data in front of each property
      // start with the known column, then spread the rest in the array
      if (this.listData.data.length > 0) {
        this.loadHeaderOrder();
      }

      this.setListDataConfig();
    });
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  loadHeaderOrder() {
    this.apiService.getInputsHeaderOrder(this.experimentId, INPUT_TYPES.SAMPLES).subscribe((res: InputsHeaderOrder) => {
      try {
        let headersJson = JSON.parse(res.headers);
        this.headers = Object.keys(headersJson).map((x, i) => headersJson[i]);
      }
      catch (ex) {
        this.headers = this.parsePropertyNames();
      }
      this.refreshPropertyPathsNamesAndDisplayedColumns();
      this.resetHeadersChanges();
    });
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    if (this.listData != null) {
      this.listData.filter = this.searchKey.trim().toLowerCase();
    }
  }

  onCreate() {
    this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
  }

  onEdit(row: any) {
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
  }

  onDelete(row: any) {
    const sampleId = row.id;
    if (this.appState.GetCurrentExperiment().status == DetailsGeneratedStatus) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to delete this sample, all associated details will be deleted as well ?'
      );

      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.deleteSample(sampleId, row);
        }
      });
    } else {
      this.deleteSample(sampleId, row);
    }
  }

  async toggleSampleUnevaluable(sample: Sample, evt: MatCheckboxChange) {
    const currentExperiment = this.appState.GetCurrentExperiment();

    const prevValue = sample.unevaluable;
    const newValue = !sample.unevaluable;

    sample.experimentId = currentExperiment.experimentId;
    sample.unevaluable = !sample.unevaluable;

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      [
        {
          identifier: `Sample ${sample.label} ${sample.name}`,
          field: 'Unevaluable',
          oldValue: prevValue ?? false,
          newValue: newValue,
        },
      ],
      this.appState.GetCurrentExperiment().type,
      {}
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || currentExperiment.type == ExperimentType.ResearchAndDevelopment)
      ) {
        await this.setSampleUnevaluableWithExceptionHandling(
          sample,
          data.batchReason ?? '',
          evt.source,
          prevValue ?? false
        );
        this.appState.SetExperimentDataChanged(this.experimentId);
      } else {
        sample.unevaluable = !sample.unevaluable;
        evt.source.checked = prevValue ?? false;
      }
    });
  }

  onInputKeyDown(evt: KeyboardEvent): void {
    const kbEvt = evt as KeyboardEventInHtmlInput;

    let targetNum = 0;
    const currentNum = +kbEvt.target.id.slice(kbEvt.target.id.lastIndexOf('-') + 1);
    const userIsNotSelecting = kbEvt.target.selectionStart == kbEvt.target.selectionEnd;

    switch (kbEvt.key) {
      case 'ArrowLeft':
        if (kbEvt.target.selectionStart == 0 && userIsNotSelecting) {
          targetNum = currentNum - 1;
        }
        break;
      case 'ArrowRight':
        if (kbEvt.target.selectionStart == kbEvt.target.value.length && userIsNotSelecting) {
          targetNum = currentNum + 1;
        }
        break;
      case 'ArrowUp':
        targetNum = currentNum - this.propertyNames.length;
        break;
      case 'ArrowDown':
        targetNum = currentNum + this.propertyNames.length;
        break;
    }

    if (targetNum != 0) {
      const newTarget = document.getElementById(`mat-input-${targetNum}`) as HTMLInputElement;

      if (newTarget && newTarget.classList.contains('modifiable-input')) {
        setTimeout(() => {
          newTarget.focus();
          newTarget.setSelectionRange(0, newTarget.value.length);
        });
      }
    }
  }

  async onSamplesEdit(): Promise<void> {
    this.prevPageSize = this.listData.paginator!.pageSize;
    this.paginator!._changePageSize(this.listData.data.length);
    this.paginator!.firstPage();

    this.newestRowIndex = this.listData.data.length - 1;
    this.newestSampleLabel = this.listData.data
      .filter(x => x.id > 0)
      .map(x => x.label)
      .sort()
    [this.listData.data.length - 1].slice(1);

    this.isEditing.emit(true);
    this.isEditMode = true;
    this.modifiedListData = {};
    for (let listItem of this.listData.data || []) {
      this.modifiedListData[listItem.id] = JSON.parse(JSON.stringify(listItem));
    }
  }

  async onSamplesEditCommit(): Promise<void> {
    let temporalRemoved: any = {};
    let newPropertyNamesReversed: any = {};
    Object.keys(this.newPropertyNames).forEach(propertyName => {
      newPropertyNamesReversed[this.newPropertyNames[propertyName]] = propertyName
    });

    let temporalModifiedListData = JSON.parse(JSON.stringify(this.modifiedListData));
    let newHeaders = [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])];
    let oldHeaders = [...this.headers, ...this.unsavedColumns];

    if (new Set(newHeaders).size !== newHeaders.length) {
      this.notificationService.error('Duplicated column.');
      return;
    }

    this.propertyNames.forEach(propertyName => {
      var newPropertyName = this.newPropertyNames[propertyName];
      if (propertyName != newPropertyName) {
        temporalRemoved[propertyName] = newPropertyName;
        for (let sample of this.listData.data) {
          this.modifiedListData[sample.id].data[newPropertyName] = temporalModifiedListData[sample.id].data[propertyName];
          if (!newHeaders.find(x => x == propertyName)) {
            delete this.modifiedListData[sample.id].data[propertyName];
          }
        }
      }
    });

    for (let sample of this.listData.data) {
      var tempObj: any = {};
      this.propertyNames.forEach(propertyName => {
        var newPropertyName = this.newPropertyNames[propertyName];
        tempObj[newPropertyName] = this.modifiedListData[sample.id].data[newPropertyName]
      });
      this.modifiedListData[sample.id].data = tempObj;
    }

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    let changeSet: IChangeSet[] = [];
    let modifiedSamples: Sample[] = [];
    let newSamples: Sample[] = [];

    const newRows = Object.keys(this.modifiedListData).filter(x => +x <= 0);
    const newRowKeys: number[] = newRows.map(x => +x);
    let newRowCount: number = newRowKeys.length;

    for (let newRowKey of newRowKeys) {
      const missingValues = Object.values(this.modifiedListData[newRowKey].data)
        .filter((x: string) => !!!x)
        .map((x: string) => x);

      if (missingValues.length > 0) {
        this.notificationService.error('All fields are required.');
        return;
      }
    }

    for (let newColKey of this.unsavedColumns) {
      for (let sampleId of Object.keys(this.modifiedListData)) {
        const colValueMissing = !!!this.modifiedListData[+sampleId].data[this.newPropertyNames[newColKey]];

        if (colValueMissing) {
          this.notificationService.error('All fields are required.');
          return;
        }
      }
    }

    let numberOfModifiedCells = 0;

    for (let originalItem of this.listData.data) {
      const originalItemData = originalItem.data;
      const modifiedItemData = this.modifiedListData[originalItem.id].data;

      if (originalItem.id > 0 && JSON.stringify(originalItemData) != JSON.stringify(modifiedItemData)) {
        modifiedSamples.push({
          sampleId: originalItem.sampleId,
          experimentId: this.experimentId,
          name: modifiedItemData[Object.keys(modifiedItemData)[0]],
          importData: JSON.stringify(modifiedItemData),
        });

        for (let field of Object.keys(modifiedItemData)) {
          if (originalItemData[field] != modifiedItemData[field] &&
            (field == this.newPropertyNames[field]) || this.unsavedColumns.find(x => x === field) || this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
            if (!!!modifiedItemData[field]) {
              this.notificationService.error('All fields are required.');
              return;
            }

            numberOfModifiedCells++;

            changeSet.push({
              identifier: `Sample ${originalItem.label}`,
              field: field,
              oldValue: originalItemData[field],
              newValue: modifiedItemData[field],
              data: JSON.stringify(modifiedItemData),
            });
          }

          if (originalItemData[newPropertyNamesReversed[field]] != modifiedItemData[field] &&
            (field != this.newPropertyNames[field]) && !this.unsavedColumns.find(x => x === field) && !this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
            changeSet.push({
              identifier: `Sample ${originalItem.label}`,
              field: newPropertyNamesReversed[field],
              oldValue: originalItemData[newPropertyNamesReversed[field]],
              newValue: modifiedItemData[field],
              data: JSON.stringify(modifiedItemData),
            });
          }
        }
      }

      if (originalItem.id <= 0) {
        newSamples.push({
          sampleId: 0,
          experimentId: this.experimentId,
          name: modifiedItemData[Object.keys(modifiedItemData)[0]],
          importData: JSON.stringify(modifiedItemData),
        });
      }
    }

    Object.keys(this.newPropertyNames).forEach(p => {
      if (this.newPropertyNames[p] != p && !this.unsavedColumns.find(x => x === p)) {
        changeSet.push({
          identifier: AuditObjectName.Samples,
          field: 'Header Renamed',
          oldValue: p,
          newValue: this.newPropertyNames[p],
        });
      }
    });

    if (newRowCount > 0) {
      changeSet.push({
        identifier: AuditObjectName.Samples,
        field: '-',
        oldValue: '-',
        newValue: `+ ${newRowCount} new rows`,
      });
    }

    const replicatePairs: IReplicatePair[] = [];

    newSamples
      .sort((a, b) => b.sampleId - a.sampleId)
      .forEach((sample, index) => {
        sample.label = `S${(+this.newestSampleLabel + (Math.abs(index) + 1)).toString().padStart(2, '0')}`;
      });

    // New combinations with new Samples as base
    if (this.conditionsListCmp?.listData.data) {
      for (let sample of newSamples) {
        for (let condition of this.conditionsListCmp?.listData.data) {
          replicatePairs.push({
            sampleId: sample.sampleId,
            sampleLabel: sample.label,
            conditionId: condition.conditionId,
            conditionLabel: condition.label,
            numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
          });
        }
      }
    }

    if (changeSet.length == 0) {
      this.restoreData(temporalRemoved, temporalModifiedListData, oldHeaders);
      return;
    }

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      {
        multiReason: true,
        isAppend: true,
        defaultReplicates: this.appState.GetCurrentExperiment().replicates,
        replicateData: replicatePairs,
      }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        let errorDuringEdit = false;

        // Makes "Loading data..." display again without breaking other type safety
        (this.listData as any) = undefined;
        this.postEditLoading = true;

        try {
          let renamedHeaders: any = {};
          Object
            .keys(this.newPropertyNames)
            .filter(p => p !== this.newPropertyNames[p] && !this.unsavedColumns.find(x => x == p))
            .forEach(p => {
              renamedHeaders[p] = this.newPropertyNames[p];
            });

          let headersOrder: any = {};
          [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])].forEach((x, i) => {
            headersOrder[i] = x;
          });
          const refreshedSamples = await this.apiService.submitSampleChangesWithAuditingAsync(
            this.experimentId,
            replicatePairs.filter(rp => !rp.ignore && (rp.numberOfReplicates ?? 0) > 0),
            newSamples,
            modifiedSamples,
            renamedHeaders,
            data?.batchReason ?? '',
            data.fieldReasons,
            JSON.stringify(headersOrder)
          );

          if (!refreshedSamples) { throw new Error("refreshedSamples is null"); }

          const headersProps: { [key: string]: number | string } = {
            ExperimentId: this.experimentId,
            RenamedHeadersCount: Object.keys(renamedHeaders).length,
            DeviceType: this.appState.GetCurrentExperiment().deviceType,
            ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          };
          this.loggingService.logEvent(EVENT_RENAME_HEADERS_SAMPLES, headersProps);

          const props: { [key: string]: number | string } = {
            ExperimentId: this.experimentId,
            AppendSampleCount: newSamples.length,
            UpdateSampleCount: modifiedSamples.length,
            ChangedSampleCellCount: numberOfModifiedCells,
            DeviceType: this.appState.GetCurrentExperiment().deviceType,
            ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          };
          this.loggingService.logEvent(EVENT_EDIT_SAMPLES, props);

          this.setNewListData(this.parseSamplesToSampleDisplayItems(refreshedSamples));
          this.refreshPropertyPathsNamesAndDisplayedColumns();
          this.resetHeadersChanges();

          this.loadHeaderOrder();
          this.appState.SetExperimentDataChanged(this.experimentId);
        } catch (err: any) {
          errorDuringEdit = true;
          const expectedErr = err.error as AuditedChangeResponse;
          if (expectedErr.expectedException) {
            this.notificationService.error(expectedErr.message);
          } else {
            throw err;
          }
        }

        this.endEditMode(errorDuringEdit);
      } else {
        this.restoreData(temporalRemoved, temporalModifiedListData, oldHeaders);
      }
    });
  }

  restoreData(temporalRemoved: any, temporalModifiedListData: any, oldHeaders: string[]) {
    Object.keys(temporalRemoved).forEach(propertyName => {
      var newPropertyName = temporalRemoved[propertyName];
      for (let sample of this.listData.data) {
        this.modifiedListData[sample.id].data[propertyName] = this.modifiedListData[sample.id].data[newPropertyName];
        if (!oldHeaders.find(x => x == newPropertyName)) {
          delete this.modifiedListData[sample.id].data[newPropertyName];
        }
      }
    });
    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();
    Object.keys(temporalRemoved).forEach(propertyName => {
      this.newPropertyNames[propertyName] = temporalRemoved[propertyName]
    });
  }

  appendNewRow(sampleItem?: ISampleDisplayItem): void {
    if (this.listData.data.length >= this.MAX_RECORDS_PER_IMPORT) {
      this.notificationService.warn('Maximum Sample records is ' + this.MAX_RECORDS_PER_IMPORT);
      return;
    }

    const curModifiedListItems = Object.keys(this.modifiedListData).sort((a, b) => +a - +b);
    const newestRealItem = this.modifiedListData[+curModifiedListItems[curModifiedListItems.length - 1]];
    const firstItem = this.modifiedListData[+curModifiedListItems[0]];

    const newestAppendedItem = firstItem.id < 0 ? firstItem : null;
    const newId = newestAppendedItem ? newestAppendedItem.id - 1 : -1;

    const newData: { [key: string]: any } = {};
    const newBlankData: { [key: string]: any } = {};
    for (let prop of Object.keys(newestRealItem.data)) {
      newBlankData[prop] = '';
      if (sampleItem) {
        newData[prop] = sampleItem.data[prop];
      }
    }

    const newItem = <ISampleDisplayItem>{
      id: newId,
      label: 'NewRow',
      name: sampleItem ? sampleItem.name : `NewRow`,
      sampleId: newId,
      unevaluable: false,
      data: sampleItem ? newData : newBlankData,
    };

    this.modifiedListData[newId] = {
      ...newItem,
      data: { ...(sampleItem ? newData : newBlankData) },
    };

    this.listData.data.push({
      ...newItem,
      data: { ...newBlankData },
    });

    this.paginator!._changePageSize(this.listData.data.length);
    this.setNewListData(this.listData.data);
  }

  addColumn() {
    const dialogRef = this.matDialog.open<AddColumnDialogComponent, any, IAddColumnResult>(AddColumnDialogComponent, {
      width: '300px',
      panelClass: 'add-column-dialog-container',
      disableClose: false,
      position: { top: '10%' },
    });

    let currentHeadersNames = [...this.headers, ...this.unsavedColumns];
    let newHeadersNames = [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])];
    dialogRef.afterClosed().subscribe(result => {
      if (result?.columnName) {
        if (currentHeadersNames.find(x => x == result.columnName) || newHeadersNames.find(x => x == result.columnName)) {
          this.notificationService.error('Column duplicated.');
          return;
        }
        this.unsavedColumns.push(result.columnName);

        this.refreshPropertyPathsNamesAndDisplayedColumns();

        this.setListDataConfig();
      }
    });
  }

  removeColumn(colName: string) {
    for (let sample of this.listData.data) {
      delete sample.data[colName];
      delete this.modifiedListData[sample.id].data[colName];
    }

    this.unsavedColumns = this.unsavedColumns.filter(uc => uc != colName);

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    this.setListDataConfig();
  }

  duplicateRow(sampleItem: ISampleDisplayItem): void {
    this.appendNewRow(sampleItem);
  }

  removeRow(sampleItem: ISampleDisplayItem): void {
    delete this.modifiedListData[sampleItem.id];
    this.setNewListData(this.listData.data?.filter(x => x.id != sampleItem.id));
  }

  endEditMode(errorOccurred: boolean = false): void {
    this.isEditing.emit(false);
    this.isEditMode = false;
    this.postEditLoading = false;
    this.modifiedListData = {};

    if (this.unsavedColumns.length > 0) {
      for (let unsavedColumn of this.unsavedColumns) {
        for (let sample of this.listData.data) {
          delete sample.data[unsavedColumn];
        }
      }

      errorOccurred = true;
    }
    this.unsavedColumns = [];

    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();

    if (errorOccurred) {
      this.ngOnInit();
    } else {
      const dataToKeep = this.listData.data.filter(d => d.id > 0);
      this.setNewListData(dataToKeep);

      setTimeout(() => {
        this.paginator!._changePageSize(this.prevPageSize);
        this.paginator!.firstPage();
      });
    }
  }

  showColumnAuditDialog() {
    this.dialogService.openChangelogDialog(
      `Change log for Samples`,
      this.experimentId,
      AuditObjectName.Samples,
      0,
      undefined,
      true
    );
  }

  showAuditDialog(sample: Sample) {
    this.dialogService.openChangelogDialog(
      `Change log for Sample ${sample.label} - ${sample.name}`,
      this.experimentId,
      AuditObjectName.Samples,
      sample.sampleId
    );
  }

  private refreshPropertyPathsNamesAndDisplayedColumns() {
    this.propertyPaths = this.parsePropertyPaths();

    this.displayedColumns = this.propertyPaths;

    // these will be only the property names, without the 'data.' prefix
    this.propertyNames = [...this.headers, ...this.unsavedColumns]//this.parsePropertyNames();
    this.propertyNames.forEach((p: string) => {
      if (!this.newPropertyNames[p]) {
        this.newPropertyNames[p] = p;
      }
    });
  }

  private resetHeadersChanges() {
    this.newPropertyNames = {};
    this.propertyNames.forEach((p: string) => {
      this.newPropertyNames[p] = p;
    });
  }

  private parsePropertyPaths() {
    return [
      'label',
      ...this.headers.map(x => `data.${x}`),
      ...this.unsavedColumns.map(x => `data.${x}`),
      'unevaluable',
      'actions',
    ];
  }

  private parsePropertyNames() {
    let maxPropIndex = 0;
    let maxPropCount = 0;
    for (let i = 0; i < this.listData.data.length; i++) {
      const curPropCount = Object.keys(this.listData.data[i].data).length;
      if (maxPropCount < curPropCount) {
        maxPropCount = curPropCount;
        maxPropIndex = i;
      }
    }
    return Object.keys(this.listData.data[maxPropIndex].data);
  }

  private parseSamplesToSampleDisplayItems(samples: Sample[]): ISampleDisplayItem[] {
    const sampleDisplayItems: ISampleDisplayItem[] = [];
    for (let sample of samples) {
      let displayItem = <ISampleDisplayItem>{
        id: sample.sampleId,
        sampleId: sample.sampleId,
        label: sample.label,
        name: sample.name,
        unevaluable: sample.unevaluable,
        createdBy: sample.createdBy,
        dateCreated: sample.dateCreated,
        data: JSON.parse(sample.importData),
      };

      sampleDisplayItems.push(displayItem);
    }
    return sampleDisplayItems;
  }

  private setNewListData(data?: ISampleDisplayItem[]) {
    this.listData = new MatTableDataSource(data);
    this.setListDataConfig();
    this.table.renderRows();
  }

  private setListDataConfig() {
    if (this.listData != null) {
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;

      this.listData.filterPredicate = (data, filter) => {
        return this.displayedColumns.some(ele => {
          if (ele == 'actions') {
            return false;
          }

          let parts = ele.split('.');
          let columnData = undefined;

          if (parts.length > 1) {
            columnData = data.data[parts[1]].toString();
          } else {
            columnData = data[ele].toString();
          }

          return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
        });
      };
    }
  }

  private async setSampleUnevaluableWithExceptionHandling(
    sample: Sample,
    batchReason: string,
    evtSource: MatCheckbox,
    prevValue: boolean
  ) {
    try {
      return await this.apiService.setSampleUnevaluableWithReasonAsync(sample, batchReason);
    } catch (err: any) {
      sample.unevaluable = !sample.unevaluable;
      evtSource.checked = prevValue;
      if (err.status == HttpStatusCode.BadRequest && err.error.expectedException) {
        this.notificationService.error(err.error.message);
      } else {
        throw err;
      }
      return;
    }
  }

  private deleteSample(sampleId: number, row: ISampleDisplayItem) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Sample ${row.label}`,
      field: 'Entire Row',
      oldValue: `Sample ${row.label}`,
      newValue: 'DELETED',
    });

    const relatedDetails = this.detailsService.getCurrentDetails().filter(d => d.sampleId == sampleId);
    if (relatedDetails && relatedDetails.length > 0) {
      changeSet.push({
        identifier: `Detail${relatedDetails.length > 1 ? 's' : ''}`,
        field: `${relatedDetails.length > 1 ? 'Multiple Rows' : 'Entire Row'}`,
        oldValue: `${relatedDetails.length} Detail Row${relatedDetails.length > 1 ? 's' : ''}`,
        newValue: `DELETED ${relatedDetails.length} related Detail${relatedDetails.length > 1 ? 's' : ''}`,
      });
    }

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteSample(this.experimentId, sampleId, data.batchReason).subscribe(
          () => {
            const index = this.listData.data?.indexOf(row, 0);
            if (index != null && index > -1) {
              this.listData.data.splice(index, 1);
            }

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            this.notificationService.success('Sample deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedSampleId: sampleId,
              SampleLabel: row.label,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_DELETE_SAMPLE, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }
}
