<div class="column-filter-activator" (click)="showColumnFilter($event)">
  <i
    class="pi {{ filterActive ? 'pi-filter-fill' : 'pi-filter' }} filter-col-icon"
    [class.active]="filterPanel.overlayVisible || filterActive"></i>
</div>

<p-overlayPanel
  #filterPanel
  styleClass="column-filter-panel"
  [dismissable]="true"
  appendTo="body"
  (onShow)="updateFilterValues()"
  (onHide)="onFilterPanelHide()">
  <ng-template pTemplate>
    <i class="pi pi-times close-btn" (click)="closeFilterPanel()"></i>
    <div class="filter-panel-content">
      <div class="filter-header-actions">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-raised p-button-default p-button-sm clear-filter-btn"
          icon="pi pi-trash"
          iconPos="left"
          label="Clear filter from {{ displayName }}"
          (click)="clearFilter()"></button>
      </div>
      <div class="filter-selection-container">
        <p-listbox
          #columnFilterListbox
          [options]="filterOptions"
          [(ngModel)]="selectedFilterOptions"
          [checkbox]="true"
          [multiple]="true"
          [filter]="true"
          [group]="true"
          styleClass="column-filter-listbox"
          listStyleClass="column-filter-list-items">
          <ng-template let-option pTemplate="group">
            <hr *ngIf="filterOptions[0].items.length > 0 && option.label === 'available'" />
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="option-label" [class.faded]="option.title == 0">{{ option.label }} ({{ option.title }})</div>
          </ng-template>
        </p-listbox>
      </div>
      <div class="filter-actions">
        <div class="clear-all-link-btn" (click)="clearAllFilters()">Clear All Filters</div>
        <button
          pButton
          pRipple
          type="button"
          class="p-button-raised p-button-primary p-button-sm apply-filter-btn"
          label="Apply"
          (click)="applyFilter()"></button>
        <button
          pButton
          pRipple
          type="button"
          class="p-button-raised p-button-default p-button-sm close-filter-btn"
          label="Cancel"
          (click)="closeFilterPanel()"></button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
