<mat-spinner class="loading-spinner" *ngIf="isLoading"></mat-spinner>

<p-multiSelect
    class="column-selector no-print"
    [options]="columnVisibilityOptions"
    [(ngModel)]="selectedColumns"
    optionLabel="header"
    selectedItemsLabel="{0} columns selected"
    [style]="{ 'min-width': '200px' }"
    placeholder="Choose Columns"
    [displaySelectedLabel]="false"
    (onChange)="onColumnSelectionChange($event)"
      >
    </p-multiSelect>

<table>
    <thead>
        <tr text-align:left>
            <ng-container *ngFor="let selectedColumn of selectedColumns;let i=index;">
                <ng-container *ngFor="let column of defaultColumns">
                    <td *ngIf="selectedColumn.header === column.dropdownHeader" [ngClass]="{'large':column.dropdownHeader == 'Barcode [string]','small':column.dropdownHeader=='Barcode [graphic]'}">
                        <div>
                            <button class="no-print" mat-icon-button *ngIf="i!=0" (click)="moveColumnLeft(i)"><mat-icon>arrow_back</mat-icon></button>
                            <span>{{column.header}}</span>
                            <button class="no-print" mat-icon-button *ngIf="i!=selectedColumns.length-1"  (click)="moveColumnRight(i)"><mat-icon>arrow_forward</mat-icon></button>
                        </div>
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let detail of detailsToPrint" text-align: left>
            <ng-container *ngFor="let col of selectedColumns">
                <td *ngIf="col.header =='Sample'">
                    {{detail.sampleLabel}} - {{detail.sampleName}}
                </td>
                <td *ngIf="col.header =='Condition'">
                    {{detail.conditionLabel}} - {{detail.conditionName}}
                </td>
                <td *ngIf="col.header =='Replicate #'">
                    {{detail.replicateNo}}
                </td>

                <td *ngIf="col.header =='Barcode [string]'" text-align: left class="large">
                    {{detail.readableBarcode}}  
                </td>
                <td *ngIf="col.header =='Barcode [graphic]'" class="small">
                    <app-barcode-print 
                        [barcodeData]="detail.readableBarcode" 
                        (onLoaded)="onBarcodeLoaded($event)"
                        [templateType]="BarcodesTemplates.DISPLAY"
                        ></app-barcode-print>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>