<div>
  <div class="legends" *ngIf="selectedTabIndex > 0">
    <div>
      <span>Positives</span> 
      <img class="result-images" src="/assets/images/result-positive.png" />
      <span>/</span>
      <span>Negatives</span> 
      <img class="result-images" src="/assets/images/result-negative.png" />
      <span>/</span>
      <span>Invalids</span> 
      <img class="result-images" src="/assets/images/result-invalid.png" />
      <span>/</span>
      <span>Unevaluables</span> 
      <mat-icon
        class="unevaluable-warning" matTooltip="Unevaluable">
        warning
      </mat-icon>
    </div>
  </div>
  <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="selectedTabChanged($event)">
    <mat-tab label="Experiments">
      <app-choose-experiment (experimentSelected)="onExperimentSelected($event)"></app-choose-experiment>
    </mat-tab>
    <mat-tab label="Results">
      <app-details-results [currentExperimentId]="selectedExperimentId" [currentExperimentOwner]="selectedExperimentOwner" (onResultsLoaded)="onResultsLoaded($event)"></app-details-results>
    </mat-tab>
    <mat-tab label="Results summary">
      <app-results-summary [experimentResults]="experimentResults"></app-results-summary>
    </mat-tab>
    <mat-tab label="Configuration" *ngIf="userAccountService.isAdmin()">
      <app-savanna-config [tabGroup]="tabGroup"></app-savanna-config>
    </mat-tab>
  </mat-tab-group>
</div>
