<div class="search-div">
  <button mat-raised-button (click)="onCreateForSerials()"
    [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"><mat-icon>add</mat-icon>Create
    Serial Bundle</button>
  <button mat-raised-button (click)="onCreateForExperiments()"
    [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"><mat-icon>add</mat-icon>Create
    Experiment Bundle</button>
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <mat-slide-toggle color="primary" [checked]="sliderActive" (change)="toggleSlider($event)">
    My Bundles
  </mat-slide-toggle>

  <div class="generating-export-container" *ngIf="generatingExport">
    <span class="loading-text">Generating export...</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div class="mat-elevation-z8 table-container">
  <mat-table
    [dataSource]="listData"
    matSort
    matSortActive="dateCreated"
    matSortDirection="desc">
    <ng-container matColumnDef="actionsEdit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button [hidden]="!showEditButton(row)" (click)="onEdit(row)" matTooltip="Edit this Bundle"  [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bundleId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.bundleId }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isClinicalTrial">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Clinical Trial</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.isClinicalTrial" id="VerifiedIcon">verified</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assayTypeCode">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Assay Type</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element | bundleAssayTypeFormat }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bundleTypeName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.bundleTypeName }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="instrumentSerialNumbers">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Instrument S/N</mat-header-cell>
      <mat-cell *matCellDef="let element" matTooltip="{{ element.instrumentSerialNumbersCount ? '(' + element.instrumentSerialNumbersCount + ')' : ''}}&nbsp;{{ element.instrumentSerialNumbers }}">        
        {{ element.instrumentSerialNumbers }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="experimentIds">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Experiment IDs</mat-header-cell>
      <mat-cell *matCellDef="let element" matTooltip="{{ element.experimentIdsCount ? '(' + element.experimentIdsCount + ')' : ''}}&nbsp;{{ element.experimentIds }}">
        {{ element.experimentIds }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.instrumentSerialNumbers.length > 0">
          {{ element.startDate | date : 'shortDate' }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.instrumentSerialNumbers.length > 0">
          {{ element.endDate | date : 'shortDate' }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.dateCreated | date : 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.createdByName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="exportXLSX">
      <mat-header-cell *matHeaderCellDef>Export XLSX</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button [hidden]="row.bundleType == 0" (click)="exportXLSX(row)" matTooltip="Export XLSX" [disabled]="generatingExport">
          <mat-icon svgIcon="xlsx"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionsDelete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          [hidden]="!showDeleteButton(row)"
          (click)="onDelete(row)"
          matTooltip="Delete this Bundle"
          [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row
      *matFooterRowDef="['loading']"
      [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{
        hide: !(listData != null && listData.data.length == 0)
      }"></mat-footer-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>

