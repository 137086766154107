<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title>New Assay config</mat-toolbar>
</div>
<div mat-dialog-content class="dialog-content">
  <mat-spinner class="loading-spinner" *ngIf="isLoading"></mat-spinner>
  <form [formGroup]="configFormGroup" (submit)="!dialogData?.currentAssayName ? onClickCreate() : onClickUpdate()">
    <mat-form-field floatLabel="always">
      <mat-label>{{ !dialogData?.currentAssayName ? 'Name' : 'New Name' }}</mat-label>
      <input
        formControlName="assayName"
        matInput
        placeholder="e.g. Savanna RVP4 510(k)"
        (focus)="configFormGroup.controls.assayName.markAsTouched()" />
      <mat-error *ngIf="configFormGroup.controls.assayName.errors?.required">This field is required.</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" *ngIf="!dialogData?.currentAssayName">
      <mat-label>Assay ID</mat-label>
      <input
        formControlName="assayId"
        matInput
        placeholder="e.g. 42"
        (focus)="configFormGroup.controls.assayId.markAsTouched()" />
      <mat-error *ngIf="configFormGroup.controls.assayId.errors?.required">This field is required.</mat-error>
      <mat-error *ngIf="configFormGroup.controls.assayId.errors?.wholeNumbersOnly">
        Only positive integers allowed.
      </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" *ngIf="!dialogData?.currentAssayName && !allRevisionsChecked">
      <mat-label>Revision</mat-label>
      <input
        formControlName="assayRevision"
        matInput
        placeholder="e.g. 10"
        (focus)="configFormGroup.controls.assayRevision.markAsTouched()" />
      <mat-error *ngIf="configFormGroup.controls.assayRevision.errors?.required">This field is required.</mat-error>
      <mat-error *ngIf="configFormGroup.controls.assayRevision.errors?.wholeNumbersOnly">
        Only positive integers allowed.
      </mat-error>
    </mat-form-field>
    <mat-checkbox
      [checked]="allRevisionsChecked"
      (change)="toggleAllRevisions($event)"
      *ngIf="!dialogData?.currentAssayName">
      All Revisions
    </mat-checkbox>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button (click)="onClickClose()">Close</button>
  <button
    mat-raised-button
    color="primary"
    (click)="!dialogData?.currentAssayName ? onClickCreate() : onClickUpdate()"
    [disabled]="!configFormGroup.valid">
    {{ !dialogData?.currentAssayName ? 'Create' : 'Update' }}
  </button>
</div>
